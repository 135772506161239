// @ts-nocheck
import React, { useState, useEffect } from "react";
import AdministrationMarkUp from "../AdministrationMarkUp";
import { fireStore } from "../../../../config/firebase";
import SingleStaff from "./SingleStaff/SingleStaff";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import PrefectImages from "../PrefectGuild/PrefectImages/PrefectImages";

const academicStaffRef = fireStore()
  .collection("academaic_staff")
  .doc("academic_staff")
  .collection("academic_staff");

const AcademicStaff = () => {
  const [allStaff, setAllStaff] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAcademicStaff();
  }, []);

  // Get Academic Staff
  const getAcademicStaff = async () => {
    let staff = [];

    try {
      await (await academicStaffRef.orderBy("updatedAt").get()).forEach(
        (item) => {
          staff = [...staff, { ...item.data(), id: item.id }];
        },
      );
      setAllStaff(staff);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <AdministrationMarkUp>
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xm={12} sm={12} md={6} lg={6} xl={6}>
            <h1 style={{ textAlign: "center" }}>Academic Staff</h1>
            {loading && <LoadingComponent />}
            {!loading && allStaff.length === 0 && <h1>No Prefect Found</h1>}
            <ul>
              {allStaff.map((staff, index) => (
                <SingleStaff
                  staff={staff}
                  key={index}
                  index={index}
                  storeRef={academicStaffRef}
                />
              ))}
            </ul>
          </Grid>
          <Grid item xm={12} sm={12} md={6} lg={6} xl={6}>
            <PrefectImages docName="academic_staff_images" />
          </Grid>
        </Grid>
      </div>
    </AdministrationMarkUp>
  );
};

export default AcademicStaff;
