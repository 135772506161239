import React from "react";
import { Image } from "semantic-ui-react";

import "./Flag.css";

const Flag = () => {
  return (
    <div className="profile" style={{ marginTop: -70 }}>
      <center>
        <h1>Our Flag</h1>
        <br />
      </center>
      <Image className="flag_image" src="./images/flag.png" alt="flag" />
    </div>
  );
};

export default Flag;
