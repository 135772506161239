import React from "react";
import MarkUp from "../MarkUp";
import Grade from "./Grade/Grade";
import { Grid } from "@material-ui/core";

const grades = [
  {
    label: "Grade 06",
    key: "Grade_06",
    image: "./images/Grade/grade_6.jpg"
  },
  {
    label: "Grade 07",
    key: "Grade_07",
    image: "./images/Grade/grade_7.jpg"
  },
  {
    label: "Grade 08",
    key: "Grade_08",
    image: "./images/Grade/grade_8.jpg"
  },
  {
    label: "Grade 09",
    key: "Grade_09",
    image: "./images/Grade/grade_9.jpg"
  },
  {
    label: "Grade 10",
    key: "Grade_10",
    image: "./images/Grade/grade_10.jpg"
  },
  {
    label: "Grade 11",
    key: "Grade_11",
    image: "./images/Grade/grade_11.jpg"
  },
  {
    label: "Grade 12",
    key: "als",
    image: "./images/Grade/grade_12.jpg"
  }
];

const Assignments = () => {
  return (
    <MarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1 className="title">Assignments</h1>
        <Grid container spacing={3}>
          {grades.map(grade => (
            <Grade
              key={grade.key}
              grade={grade.label}
              link={grade.key}
              image={grade.image}
            />
          ))}
        </Grid>
      </div>
    </MarkUp>
  );
};

export default Assignments;
