import React from "react";
import LoadingComponent from "../../../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import SinglePrinciple from "../SinglePrinciple/SinglePrinciple";

const Assistant = ({ principles, loading }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Assistant Principles</h1>
      {loading && <LoadingComponent />}
      <Grid container spacing={2}>
        {principles.map(principle => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={principle.id}>
            <SinglePrinciple
              principle={principle}
              type={"assistant_principles"}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Assistant;
