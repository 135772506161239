import React from "react";
import LoadingComponent from "../../../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import SinglePrinciple from "../SinglePrinciple/SinglePrinciple";

const Duputy = ({ principles, loading }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Duputy Principles</h1>
      {loading && <LoadingComponent />}
      <Grid container spacing={2}>
        {principles.map(principle => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={principle.id}>
            <SinglePrinciple principle={principle} type={"duputy_principles"} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Duputy;
