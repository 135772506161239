import React from "react";
import { Card, Icon } from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { fireStore, storage } from "../../../../config/firebase";
import { useParams } from "react-router-dom";

const SingleAssignment = ({ assignment, userId, deleted, setDeleted }) => {
  const { grade, subject } = useParams();

  // Delete Assignment
  const deleteAssignment = id => {
    if (window.confirm("Are you Sure")) {
      deleteFireStore(id);
    }
  };
  const newGrade = grade === "Grade 12" ? "Advance Level" : grade;

  // Delete From Fire Store
  const deleteFireStore = async id => {
    try {
      await fireStore()
        .collection("assignments")
        .doc(newGrade)
        .collection(subject)
        .doc(id)
        .delete();
      if (assignment.assignmentUrl) {
        deleteFromStorage();
      } else {
        alert("Deleted");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From Firebase Storage
  const deleteFromStorage = () => {
    const deleteStorageRef = storage()
      .ref(`assignments/${newGrade}/${subject}`)
      .child(assignment.name);

    // Delete the file
    deleteStorageRef
      .delete()
      .then(() => {
        // File deleted successfully
        setDeleted(!deleted);
        alert("Deleted");
      })
      .catch(err => {
        alert(err.message);
      });
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={4}
      md={4}
      style={{
        textAlign: "center",
        margin: "auto",
        justifyContent: "center",
        alignSelf: "center"
      }}
    >
      <Card
        style={{ height: 200, borderStyle: "solid", borderColor: "darkgray" }}
        className="card"
      >
        {assignment.specialNot && (
          <p style={{ fontSize: "18px", fontWeight: "1000" }}>
            {assignment.specialNot}
          </p>
        )}
        <p style={{ fontSize: "12px" }}>
          {assignment.createdAt.toString().substr(0, 15)}
        </p>
        <br />
        {assignment.assignmentUrl && (
          <>
            <a
              href={assignment.assignmentUrl}
              target="__blank"
              className="assigmnt_btn"
            >
              Download Assignment
            </a>
            <br />
          </>
        )}
        {assignment.quiz && (
          <a href={assignment.quiz} target="__blank" className="assigmnt_btn">
            Click Here
          </a>
        )}
        <br />
        {userId && (
          <div style={{ marginLeft: 230, marginTop: -15 }}>
            <Icon
              onClick={() => deleteAssignment(assignment.id)}
              style={{ color: "darkred", cursor: "pointer" }}
              name="trash"
              size="big"
            />
          </div>
        )}
      </Card>
      <br />
      <br />
      <br />
      <br />
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};
export default connect(mapStateToProps)(SingleAssignment);
