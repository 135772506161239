import React, { useState } from "react";
import AdminMarkUp from "../AdminMarkUp";
import { storage, fireStore } from "../../../../config/firebase";
import { Form, Select, Input, Button, Icon } from "semantic-ui-react";

const options = [
  { key: "Society", text: "Clubs and Society", value: "clubs" },
  { key: "Sports", text: "Sports", value: "sports" }
];

const AddSportAndSociety = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  // Upload Club
  const uploadClub = e => {
    e.preventDefault();
    setLoading(true);
    if (!name || members.length === 0 || !image || !type) {
      alert("Fill All the Fileds");
      setLoading(false);
    } else {
      uploadFireStorage();
    }
  };

  const uploadFireStorage = () => {
    storage()
      .ref(type)
      .child(`${image.name}`)
      .put(image)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref(`${type}/`)
            .child(`${image.name}`)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };

  const uploadFireStore = url => {
    fireStore()
      .collection(type)
      .add({
        society_name: name,
        members,
        updatedAt: Date.now(),
        image: url,
        imageName: image.name
      })
      .then(() => {
        alert("Uploaded");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <AdminMarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>Add Society and Clubs</h1>
        <div style={{ textAlign: "center" }}>
          <Form.Field>
            <Select
              value={type}
              onChange={(e, data) => {
                setType(data.value);
              }}
              placeholder="Select Type"
              options={options}
              multiple={false}
            />
          </Form.Field>
          <br />
          <Form.Field>
            <Input
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              placeholder="Enter Club or Sport Name"
            />
          </Form.Field>
          <br />
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Form.Field>
              <Input
                style={{ textAlign: "center" }}
                placeholder="Add Members"
                value={member}
                onChange={e => setMember(e.target.value)}
              />
            </Form.Field>
            <Button
              name="Add"
              style={{ color: "black", marginLeft: 10 }}
              onClick={() => {
                setMembers([...members, member]);
                setMember("");
              }}
            >
              Add Member
            </Button>
          </div>
          <br />
          <Form.Field>
            <Input
              type="file"
              onChange={e => setImage(e.target.files[0])}
              multiple={false}
            />
          </Form.Field>
          <br />
          <Button
            type="submit"
            color="green"
            loading={loading}
            onClick={e => uploadClub(e)}
          >
            Upload Club
          </Button>
        </div>
      </div>

      <ul>
        {members.map((member, index) => (
          <>
            <span style={{ color: "white" }}>{member}</span>{" "}
            <Icon
              name="delete"
              style={{ color: "darkRed" }}
              onClick={() => {
                setMembers(members.filter((mem, i) => i !== index));
              }}
            />
          </>
        ))}
      </ul>
    </AdminMarkUp>
  );
};

export default AddSportAndSociety;
