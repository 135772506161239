import React from "react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();

  return (
    <>
      <br />
      <footer className="footer" id="footer">
        <div style={{ cursor: "pointer" }} className="footer-inner">
          <span onClick={() => history.push("/login")}>
            &copy; Ananda Balika Vidyalaya ICT Center {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
