import React, { useState } from "react";
import AdminMarkUp from "../AdminMarkUp";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import { fireStore, storage } from "../../../../config/firebase";

const AddPrincipleMessageAndImage = () => {
  const [pricipleMessage, setPricipleMessage] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Upload Principle Message
  const uploadPrincipleMessage = async () => {
    setLoading(true);
    try {
      if (!pricipleMessage) {
        alert("Please and Principle Message");
        setLoading(false);
      } else if (image) {
        uploadFireStorage();
      } else {
        uploadFireStore(null);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const uploadFireStorage = () => {
    storage()
      .ref("principle_messages")
      .child(image.name)
      .put(image)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref("principle_messages")
            .child(image.name)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };

  const uploadFireStore = url => {
    const time = new Date().getTime();
    const dates = new Date(time);

    fireStore()
      .collection("principle_messages")
      .add({
        image: url ? url : null,
        message: pricipleMessage,
        createdAt: dates.toString(),
        updatedAt: Date.now(),
        name: image ? image.name : null
      })
      .then(() => {
        alert("Uploaded");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <AdminMarkUp>
      <div style={{ textAlign: "center" }}>
        <h1>Add Principle Image And Message</h1>
        <Form onSubmit={e => uploadPrincipleMessage(e)}>
          <Form.Field>
            <TextArea
              value={pricipleMessage}
              onChange={e => {
                setPricipleMessage(e.target.value);
              }}
              placeholder="Description"
            />
          </Form.Field>

          <Form.Field>
            <Input
              type="file"
              onChange={e => setImage(e.target.files[0])}
              multiple="false"
              accept="image/*"
            />
          </Form.Field>
          <Button type="submit" color="green" loading={loading}>
            Upload Principle Message
          </Button>
        </Form>
      </div>
    </AdminMarkUp>
  );
};

export default AddPrincipleMessageAndImage;
