import React, { useState } from "react";
import { connect } from "react-redux";
import AdminMarkUp from "../AdminMarkUp";
import { Form, Input, TextArea, Button, Select } from "semantic-ui-react";
import { fireStore, storage } from "../../../../config/firebase";

const AddNews = ({ userId }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);
  const [link, setLink] = useState("");

  const options = [
    { key: "News", text: "News", value: "News" },
    { key: "Events", text: "Events", value: "Events" }
  ];

  // Upload News
  const uploadNews = e => {
    e.preventDefault();
    if (!type) {
      alert("Select Image Or News Type");
      return;
    } else if (
      type === "Events" &&
      (!title || !description || !image || !date)
    ) {
      alert("Fill All the Fields");
      setLoading(false);
      return;
    } else if (type === "News" && (!title || !description || !date)) {
      alert("To Upload News You Need To fill Title, Date and Description");
      return;
    } else {
      setLoading(true);
      if (image) {
        uploadFireStorage();
      } else {
        uploadFireStore(null);
      }
    }
  };

  const uploadFireStorage = () => {
    storage()
      .ref(type.toLowerCase())
      .child(image.name)
      .put(image)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref(`${type.toLowerCase()}`)
            .child(image.name)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };

  const uploadFireStore = url => {
    const time = new Date().getTime();
    const dates = new Date(time);

    fireStore()
      .collection(type.toLowerCase())
      .add({
        image: url,
        title,
        description,
        date,
        link: type === "News" ? link : null,
        createdBy: userId,
        createdAt: dates.toString(),
        updatedAt: Date.now(),
        name: image ? image.name : null
      })
      .then(() => {
        alert("Uploaded");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <AdminMarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>Add News Or Events</h1>
        <Form onSubmit={e => uploadNews(e)}>
          <Form.Field>
            <Select
              value={type}
              onChange={(e, data) => {
                setType(data.value);
              }}
              placeholder="News or Image"
              options={options}
              multiple={false}
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              placeholder="Title"
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={date}
              onChange={e => {
                setDate(e.target.value);
              }}
              placeholder="Date"
            />
          </Form.Field>

          {type === "News" && (
            <Form.Field>
              <Input
                value={link}
                onChange={e => {
                  setLink(e.target.value);
                }}
                placeholder="Link"
              />
            </Form.Field>
          )}
          <Form.Field>
            <TextArea
              value={description}
              onChange={e => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
            />
          </Form.Field>

          <Form.Field>
            <Input
              type="file"
              onChange={e => setImage(e.target.files[0])}
              multiple="false"
              accept="image/*"
            />
          </Form.Field>
          <Button type="submit" color="green" loading={loading}>
            Upload News
          </Button>
        </Form>
      </div>
    </AdminMarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(AddNews);
