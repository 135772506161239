import React, { useState } from "react";
import { connect } from "react-redux";
import AdminMarkUp from "../AdminMarkUp";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import { fireStore, storage } from "../../../../config/firebase";

const AddPrinciple = ({ userId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [number, setNumber] = useState(null);
  const [serviceYear, setServiceYear] = useState(null);
  const [loading, setLoading] = useState(false);

  // Upload News
  const uploadNews = e => {
    e.preventDefault();
    setLoading(true);
    if (!name || !description || !image || !number) {
      alert("Fill All the Fields");
      setLoading(false);
    } else {
      uploadFireStorage();
    }
  };

  const uploadFireStorage = () => {
    storage()
      .ref("principle")
      .child(image.name)
      .put(image)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref(`principle/`)
            .child(image.name)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };

  const uploadFireStore = url => {
    const time = new Date().getTime();
    const date = new Date(time);

    fireStore()
      .collection("principles")
      .add({
        image: url,
        name: name,
        number,
        description,
        serviceYear,
        createdBy: userId,
        createdAt: date.toString(),
        updatedAt: Date.now(),
        imageName: image.name
      })
      .then(() => {
        alert("Upload Principle");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <AdminMarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>Add Principles</h1>
        <Form onSubmit={e => uploadNews(e)}>
          <Form.Field>
            <Input
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              placeholder="Principle Name"
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={number}
              type="number"
              onChange={e => {
                setNumber(e.target.value);
              }}
              placeholder="Number of Principle"
            />
          </Form.Field>

          <Form.Field>
            <Input
              value={serviceYear}
              type="text"
              onChange={e => {
                setServiceYear(e.target.value);
              }}
              placeholder="Service Years"
            />
          </Form.Field>

          <Form.Field>
            <TextArea
              value={description}
              onChange={e => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
            />
          </Form.Field>

          <Form.Field>
            <Input
              type="file"
              onChange={e => setImage(e.target.files[0])}
              multiple="false"
              accept="image/*"
            />
          </Form.Field>
          <Button type="submit" color="green" loading={loading}>
            Upload News
          </Button>
        </Form>
      </div>
    </AdminMarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(AddPrinciple);
