import React from "react";
import SlideShowNavBar from "./SlideShowNavBar/SlideShowNavBar";

const SlideShowMarkUp = ({ children }) => {
  return (
    <div>
      <br />
      <SlideShowNavBar />
      {children}
    </div>
  );
};

export default SlideShowMarkUp;
