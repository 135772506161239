import { CHECK_AUTH_STATE, LOGOUT_USER } from "../../types";
import { auth } from "../../../config/firebase";

// Check Auth State
export const checkAuthState = () => async dispatch => {
  try {
    let authenticated = false;
    await auth().onAuthStateChanged(user => {
      if (user) {
        authenticated = true;
      }
      dispatch({
        type: CHECK_AUTH_STATE,
        payload: { auth: authenticated }
      });
    });
  } catch (err) {
    console.error(err.message);
  }
};

// Register User
export const registerUser = (email, password, setLoading) => async dispatch => {
  try {
    await auth().createUserWithEmailAndPassword(email, password);
    setLoading(false);
    alert("Registered");
  } catch (err) {
    alert(err.message);
    setLoading(false);
  }
};

// Login User
export const loginUser = (email, password, setLoading) => async dispatch => {
  try {
    await auth().signInWithEmailAndPassword(email, password);
    setLoading(false);
  } catch (err) {
    alert(err.message);
    setLoading(false);
  }
};

// LogOut
export const logoutUser = () => async dispatch => {
  try {
    await auth().signOut();
    dispatch({
      type: LOGOUT_USER
    });
  } catch (err) {
    alert(err.message);
  }
};

// Send Reset Link
export const sendResetLink = email => async dispatch => {
  try {
    auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(`Reset Password Link Send To ${email} Email Address`);
      })
      .catch(err => alert(err.message));
  } catch (err) {
    console.error(err.message);
  }
};
