/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { fireStore } from "../../../../config/firebase";
import { Icon } from "semantic-ui-react";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { deleteFunctions } from "../../../../helpers/deleteFunctions";
import { connect } from "react-redux";

const newsRef = fireStore().collection("news");

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "auto",
    background: "darkGrey"
  },
  media: {
    height: 140
  }
});

const News = ({ userId }) => {
  const classes = useStyles();

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    let newses = [];
    try {
      await (await newsRef.orderBy("updatedAt", "desc").limit(4).get()).forEach(
        news => {
          newses = [...newses, { ...news.data(), id: news.id }];
        }
      );
      setNews(newses);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete News
  const deleteNews = async news => {
    deleteFunctions(news, "news");
  };

  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <h1
        style={{
          textAlign: "center",
          color: "rgb(197, 199, 199)",
          fontFamily: "Playfair Display"
        }}
      >
        Latest News
      </h1>
      {loading && <LoadingComponent />}
      {!loading && news.length === 0 && <h1>No News Found</h1>}
      {news.map(ne => (
        <div key={ne.id}>
          <Card className={classes.root}>
            <CardActionArea>
              {ne.image && (
                <CardMedia
                  className={classes.media}
                  image={ne.image}
                  title="Contemplative Reptile"
                />
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {ne.title}
                </Typography>
                {ne?.link && (
                  <a href={ne.link} target="__blank">
                    Link
                  </a>
                )}
                <Typography variant="body2" color="textSecondary" component="p">
                  {ne.description}
                </Typography>
              </CardContent>
            </CardActionArea>
            {userId && (
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => deleteNews(ne)}
                >
                  <Icon
                    name="trash"
                    size="large"
                    style={{ color: "darkRed" }}
                  />
                </Button>
              </CardActions>
            )}
          </Card>
          <br />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(News);
