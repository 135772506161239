import { createStore, applyMiddleware } from "redux";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { reduxFirestore, getFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import firebase from "../../config/firebase";

import rootReducer from "../reducers";

const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true
};

const middlewares = [
  thunk.withExtraArgument({
    getFirebase,
    getFirestore
  })
];

const composeWithEnhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase)
);

const configureStore = createStore(rootReducer, composeWithEnhancer);
export default configureStore;
