import React from "react";

const VisionAndMission = () => {
  return (
    <div className='profile'>
      <center><h1>Our Vision</h1></center>
      <p>To create a generation of intelligent, virtuous Buddhist women who can
      face the challenges of the 21st century.</p>
      <br/>
      <center><h1>Mission</h1></center>
      <p>Our mission is to provide a good learning atmosphere in order to create an
      excellent student body with a good personality to face the challenges of
      the future with knowledge, intuition and good qualities in line with the
      national and educational objectives.</p>
    </div>
  );
};

export default VisionAndMission;
