/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Grid } from "@material-ui/core";
import { Image } from "semantic-ui-react";

const HomeFooter = () => {
  return (
    <div className="container">
      <Grid container spacing={4}>
        <Grid
          item
          xm={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <a href="http://www.moe.gov.lk/" target="_blank">
            <Image src="./images/Nav/edu.png" />
          </a>
        </Grid>
        <Grid
          item
          xm={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <a href="https://www.doenets.lk/" target="_blank">
            <Image src="./images/Nav/exam.png" />
          </a>
        </Grid>
        <Grid
          item
          xm={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <a href="http://www.e-thaksalawa.moe.gov.lk/web/en/" target="_blank">
            <Image src="./images/Nav/ethaksala.png" />
          </a>
        </Grid>
        <Grid
          item
          xm={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <a href="http://nie.lk/default" target="_blank">
            <Image src="./images/Nav/nienw.png" />
          </a>
        </Grid>
        <Grid
          item
          xm={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <a href="http://www.edupub.gov.lk/" target="_blank">
            <Image src="./images/Nav/edupub.png" />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeFooter;

// hjashajshajhsajsh
