/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MarkUp from "../MarkUp";
import { useParams } from "react-router-dom";
import { fireStore } from "../../../config/firebase";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import SingleAssignment from "./SingleAssignment/SingleAssignment";
import { Button } from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";

const AssignmentsLists = () => {
  const { grade, subject } = useParams();
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [allAssignments, setAllAssignments] = useState(0);
  const [deleted, setDeleted] = useState(false);

  const collectionRef = fireStore().collection("assignments");

  const newGrade = grade === "Grade 12" ? "Advance Level" : grade;
  // Get Assignments
  const getAssignments = async () => {
    let assign = [];
    try {
      await (
        await collectionRef
          .doc(newGrade)
          .collection(subject)
          .orderBy("updatedAt", "desc")
          .limit(12)
          .get()
      ).forEach(doc => {
        assign = [...assign, { ...doc.data(), id: doc.id }];
      });
      setAssignments(assign);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  // Get Size
  const getSize = async () => {
    try {
      await collectionRef
        .doc(newGrade)
        .collection(subject)
        .get()
        .then(doc => {
          console.log(doc.size);
          setAllAssignments(doc.size);
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  useEffect(() => {
    getAssignments();
    getSize();
  }, [deleted]);

  // Next Page
  const nextPage = async () => {
    const last = assignments[assignments.length - 1];
    let assign = [];
    try {
      await (
        await collectionRef
          .doc(grade)
          .collection(subject)
          .orderBy("updatedAt", "desc")
          .startAfter(last["updatedAt"])
          .limit(12)
          .get()
      ).forEach(doc => {
        assign = [...assign, { ...doc.data(), id: doc.id }];
      });
      setAssignments(assign);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
    setPage(page + 1);
  };

  // Previous Page
  const previousPage = async () => {
    const first = assignments[0];
    let assign = [];
    try {
      await (
        await collectionRef
          .doc(grade)
          .collection(subject)
          .orderBy("updatedAt", "desc")
          .endBefore(first["updatedAt"])
          .limitToLast(12)
          .get()
      ).forEach(doc => {
        assign = [...assign, { ...doc.data(), id: doc.id }];
      });
      setAssignments(assign);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
    setPage(page - 1);
  };

  return (
    <MarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1 className="title">
          {grade === "Grade 12" ? "Advance Level" : grade}{" "}
          {subject === "Easthetic" ? "Aesthetic" : subject} {allAssignments}{" "}
          Assignments
        </h1>
        {!loading && assignments.length === 0 && <h3>No Assignments</h3>}
        {loading && <LoadingComponent />}
        <Grid
          spacing={3}
          container
          style={{
            textAlign: "center",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {assignments.map(assignment => (
            <SingleAssignment
              key={assignment.id}
              assignment={assignment}
              deleted={deleted}
              setDeleted={setDeleted}
            />
          ))}
        </Grid>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="green"
          style={{ marginLeft: 10 }}
          disabled={page === 1}
          onClick={() => previousPage()}
        >
          Previous Page
        </Button>
        <Button
          disabled={allAssignments <= page * 12}
          color="green"
          style={{ marginRight: 10 }}
          onClick={() => nextPage()}
        >
          Next Page
        </Button>
      </div>
    </MarkUp>
  );
};

export default AssignmentsLists;
