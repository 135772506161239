import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

const MarkUp = ({ children }) => {
  return (
    <div className="page_container">
      <div className="content_wrap">
        <NavBar />

        <div
          onClick={() =>
            document.querySelector(".main-menu").classList.remove("show")
          }
        >
          {children}
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
};
export default MarkUp;
