import React, { useState, useEffect } from "react";
import { fireStore } from "../../../../config/firebase";
import { connect } from "react-redux";
import { Icon, Input, Button } from "semantic-ui-react";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const schoolDetailsRef = fireStore().collection("contact_info");

const Info = ({ userId }) => {
  const [loading, setLoading] = useState(true);

  const [allDetails, setAllDetails] = useState([]);

  useEffect(() => {
    getSchoolInfo();
  }, []);

  // Get School Info
  const getSchoolInfo = async () => {
    let allItems = [];
    try {
      // Set Name
      await (await schoolDetailsRef.orderBy("number").get()).forEach(item => {
        allItems = [
          ...allItems,
          { ...item.data(), id: item.id, isEditOpen: false }
        ];
      });
      setAllDetails(allItems);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  // Edit Details
  const editDetails = async ref => {
    const details = allDetails.filter(detai => detai.id === ref)[0];
    delete details.id;

    try {
      await schoolDetailsRef.doc(ref).update(details);
      alert("Updated");
    } catch (err) {
      alert(err.message);
    }
  };

  // Sort
  const compare = (a, b) => {
    const bandA = a.number;
    const bandB = b.number;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  return (
    <div className="container">
      <div className='contact-info'>
      <h1>Contact Info</h1>
      {loading && <LoadingComponent />}
      <ul>
        {allDetails.map(detail => (
          <div key={detail.id}>
            <li>
              <h2>{detail.type}</h2>  {detail.name}
            </li>
            {userId && (
              <Icon
                name="edit"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const otherDetails = allDetails.filter(
                    det => det.id !== detail.id
                  );
                  const rightDetail = allDetails.filter(
                    det => det.id === detail.id
                  )[0];
                  rightDetail.isEditOpen = !rightDetail.isEditOpen;
                  const filterAll = [...otherDetails, rightDetail];
                  setAllDetails(filterAll.sort(compare));
                }}
              />
            )}
            <br />
            <br />
            {detail.isEditOpen && (
              <>
                <Input
                  style={{ width: 300, marginRight: 10 }}
                  value={detail.name}
                  onChange={e => {
                    const otherDetails = allDetails.filter(
                      det => det.id !== detail.id
                    );
                    const rightDetail = allDetails.filter(
                      det => det.id === detail.id
                    )[0];
                    rightDetail.name = e.target.value;
                    const filterAll = [...otherDetails, rightDetail];
                    setAllDetails(filterAll.sort(compare));
                  }}
                />
                <Button color="green" onClick={() => editDetails(detail.id)}>
                  Edit
                </Button>
              </>
            )}
          </div>
        ))}
      </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(Info);
