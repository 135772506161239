// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import SingleGallery from "./SingleGallery/SingleGallery";
import MarkUp from "../MarkUp";
import { fireStore } from "../../../config/firebase";
import { Button } from "semantic-ui-react";
import LoadingComponent from "../../components/Loading/LoadingComponent";

const galleryRef = fireStore().collection("event_gallery");

const Gallery = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState(0);
  const [page, setPage] = useState(1);

  // Get Gallery
  const getEventGallery = async () => {
    let evts = [];
    try {
      await galleryRef.get().then(doc => setAllEvents(doc.size));
      await (
        await galleryRef.orderBy("updatedAt", "desc").limit(9).get()
      ).forEach(itm => {
        evts = [...evts, { ...itm.data(), id: itm.id }];
      });
      setEvents(evts);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getEventGallery();
  }, []);

  // Next Page
  const nextPage = async () => {
    const last = events[events.length - 1];
    let evts = [];
    try {
      await (
        await galleryRef
          .orderBy("updatedAt", "desc")
          .startAfter(last["updatedAt"])
          .limit(9)
          .get()
      ).forEach(doc => {
        evts = [...evts, { ...doc.data(), id: doc.id }];
      });
      setEvents(evts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
    setPage(page + 1);
  };

  // Previous Page
  const previousPage = async () => {
    const first = events[0];
    let evts = [];
    try {
      await (
        await galleryRef
          .orderBy("updatedAt", "desc")
          .endBefore(first["updatedAt"])
          .limitToLast(9)
          .get()
      ).forEach(doc => {
        evts = [...evts, { ...doc.data(), id: doc.id }];
      });
      setEvents(evts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
    setPage(page - 1);
  };

  return (
    <MarkUp>
      <div className="container">
        <center>
          <h1
            style={{ textAlign: "center", marginBottom: "2em" }}
            className="contactus"
          >
            Events Gallery
          </h1>
        </center>
        {loading && <LoadingComponent />}
        {!loading && events.length === 0 && <h1>Images Not Found</h1>}
        <Grid container spacing={3}>
          {events.map(evt => (
            <SingleGallery key={evt.id} evt={evt} />
          ))}
        </Grid>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="green"
          style={{ marginLeft: 10 }}
          disabled={page === 1}
          onClick={() => previousPage()}
        >
          Previous Page
        </Button>
        <Button
          disabled={allEvents <= page * 9}
          color="green"
          style={{ marginRight: 10 }}
          onClick={() => nextPage()}
        >
          Next Page
        </Button>
      </div>
    </MarkUp>
  );
};

export default Gallery;
