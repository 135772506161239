import React from "react";
import { Image } from "semantic-ui-react";
import "./Logo.css";

const Logo = () => {
  return (
    <div style={{ textAlign: "center" }} className="profile">
      <center>
        <h1>Our Logo</h1>
      </center>
      <Image className="logo_image" src="./images/logo.png" alt="logo" />
    </div>
  );
};

export default Logo;
