// Async
export const ASYNC_ACTION_START = "ASYNC_ACTION_START";
export const ASYNC_ACTION_FINISHED = "ASYNC_ACTION_FINISHED";
export const ASYNC_ACTION_ERROR = "ASYNC_ACTION_ERROR";

// Auth
export const CHECK_AUTH_STATE = "CHECK_AUTH_STATE";
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";

// Slide Show
export const ADD_SLIDE_SHOW_IMAGES = "ADD_SLIDE_SHOW_IMAGES";
export const REMOVE_SLIDE_SHOW_IMAGE = "REMOVE_SLIDE_SHOW_IMAGE";
export const GET_SLIDE_SHOW_IMAGES = "GET_SLIDE_SHOW_IMAGES";

// Chat List
export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
export const CHANGE_USER_ONLINE = "CHANGE_USER_ONLINE";
export const DELETE_CHAT = "DELETE_CHAT";
export const CHANGE_POSITION = "CHANGE_POSITION";
export const CHANGE_LAST_MESSAGE = "CHANGE_LAST_MESSAGE";

// New Message
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
