import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MarkUp from "../../MarkUp";
import { fireStore, storage } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Image, Icon, Form, Input, Button } from "semantic-ui-react";

const gceOLRef = fireStore().collection("academic").doc("olResults");

const GCEOL = ({ userId }) => {
  const [results, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState(null);

  // Get Data
  const getData = async () => {
    try {
      setResult(await (await gceOLRef.get()).data());
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete From Fire Store

  // Delete From Firebase Storage
  const deleteFromStorage = () => {
    const deleteStorageRef = storage()
      .ref(`results/`)
      .child(results?.imageName);

    // Delete the file
    deleteStorageRef
      .delete()
      .then(() => {
        // File deleted successfully
        alert("Deleted");
      })
      .catch(err => {
        alert(err.message);
      });
  };

  // Upload To Firebase Storage
  const uploadToStorage = async () => {
    try {
      storage()
        .ref(`results/${image.name}`)
        .put(image)
        .on(
          "state_changed",
          snapshot => {
            // console.log(snapshot);
          },
          error => {
            alert(error.message);
            setUploadLoading(false);
          },
          async () => {
            // error
            await storage()
              .ref(`results/`)
              .child(image.name)
              .getDownloadURL()
              .then(async url => {
                uploadFireStore(url);
              })
              .catch(err => {
                setUploadLoading(false);
                alert(err.message);
              });
          }
        );
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Upload FireStore
  const uploadFireStore = url => {
    gceOLRef
      .update({ year: results.year, image: url, imageName: image.name })
      .then(() => {
        setUploadLoading(false);
        alert("Updated");
      })
      .catch(err => {
        setUploadLoading(false);
        alert(err.message);
      });
  };

  // Update Result
  const updateResult = async e => {
    e.preventDefault();
    try {
      if (!image) {
        alert("Please Fill All the Fields");
      } else {
        deleteFromStorage();
        setUploadLoading(true);
        uploadToStorage();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <MarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>O/L Results</h1>
        {loading && <LoadingComponent />}
        {!loading && !results && <h1>No Results Found</h1>}
        {results && (
          <>
            <h1>Year: {results?.year}</h1>
            <Image style={{ margin: "auto" }} src={results?.image} />
          </>
        )}
        {userId && (
          <Icon
            name="edit"
            size="large"
            style={{ cursor: "pointer" }}
            onClick={() => setEdit(!edit)}
          />
        )}
        <br />
        <br />
        {userId && edit && (
          <>
            <Form onSubmit={e => updateResult(e)}>
              <Form.Field>
                <Input
                  placeholder="Year"
                  value={results?.year}
                  onChange={e =>
                    setResult({ ...results, year: e.target.value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Input
                  type="file"
                  placeholder="Year"
                  onChange={e => setImage(e.target.files[0])}
                />
              </Form.Field>
              <Button color="green" loading={uploadLoading}>
                Update Result
              </Button>
            </Form>
          </>
        )}
      </div>
    </MarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};
export default connect(mapStateToProps)(GCEOL);
