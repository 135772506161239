import React from "react";
import { Image } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

const Subjects = ({ subject }) => {
  const history = useHistory();

  const optionalOne = [
    "Commerce",
    "Tamil",
    "Geography",
    "Civics",
    "Hindi",
    "Japanese",
    "French"
  ];
  const optionalTwo = ["Sinhala lit", "English lit", "Aesthetic"];
  const optionalThree = [
    "ICT",
    "Health",
    "Home Science",
    "Agriculture",
    "Communication and Media Studies"
  ];
  const optionalSubjects = [
    "Commerce",
    "Tamil",
    "Geography",
    "Civics",
    "Hindi",
    "Japanese",
    "French",
    "Sinhala lit",
    "English lit",
    "Aesthetic",
    "ICT",
    "Health",
    "Home Science",
    "Agriculture",
    "Communication and Media Studies"
  ];

  const { grade } = useParams();
  const gradeAsNumber = parseInt(grade.split(" ")[1]);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={4}
      md={4}
      style={{ cursor: "pointer", padding: 20 }}
    >
      <div onClick={() => history.push(`/assign/${grade}/${subject}`)}>
        {gradeAsNumber < 10 && (
          <Image
            src={`../images/Subjects/${subject}.jpg`}
            style={{ borderRadius: "10px" }}
          />
        )}
        {gradeAsNumber === 12 && (
          <Image
            src={`../images/Subjects/${subject}.jpg`}
            style={{ borderRadius: "10px" }}
          />
        )}
        {gradeAsNumber >= 10 &&
          gradeAsNumber < 12 &&
          !optionalSubjects.includes(subject) && (
            <Image
              src={`../images/Subjects/${subject}.jpg`}
              style={{ borderRadius: "10px" }}
            />
          )}
        {gradeAsNumber >= 10 &&
          gradeAsNumber < 12 &&
          optionalOne.includes(subject) && (
            <Image
              src={`../images/Subjects/Basket I.jpg`}
              style={{ borderRadius: "10px" }}
            />
          )}
        {gradeAsNumber >= 10 &&
          gradeAsNumber < 12 &&
          optionalTwo.includes(subject) && (
            <Image
              src={`../images/Subjects/Basket II.jpg`}
              style={{ borderRadius: "10px" }}
            />
          )}
        {gradeAsNumber >= 10 &&
          gradeAsNumber < 12 &&
          optionalThree.includes(subject) && (
            <Image
              src={`../images/Subjects/Basket III.jpg`}
              style={{ borderRadius: "10px" }}
            />
          )}
        <br />
        <p className="title">
          {subject === "Easthetic" ? "Aesthetic" : subject}
        </p>
        <br />
      </div>
    </Grid>
  );
};

export default Subjects;
