/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Image, Icon, Form, Input, Button } from 'semantic-ui-react';
import { fireStore, storage } from '../../../../../config/firebase';
import LoadingComponent from '../../../../components/Loading/LoadingComponent';

const PrefectImages = ({ userId, docName }) => {
  const [prefectImages, setPrefectImages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState([]);

  const prefectImagesRef = fireStore()
    .collection('academaic_staff')
    .doc(docName);

  // Get Prefect Images
  const getPrefectImages = async () => {
    try {
      setPrefectImages(await (await prefectImagesRef.get()).data());
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getPrefectImages();
  }, []);

  // Add Images to State
  const selectUploadImages = (files) => {
    if (Array.from(files).length + images.length > 14) {
      alert('Please Select Only One Image');
      const filterImages = Array.from(files).filter(
        (image, index) => index < 14
      );
      const allImages = [...images, ...filterImages];
      const filterAllImages = allImages.filter((images, index) => index < 14);
      setImages(filterAllImages);
    } else {
      setImages([...images, ...Array.from(files)]);
    }
  };

  // Upload To Firebase Storage
  const uploadToStorage = async () => {
    let imagesUrls = [];
    let imagesName = [];
    try {
      images.map((image) => {
        imagesName = [...imagesName, image.name];
        storage()
          .ref(`${docName}/${image.name}`)
          .put(image)
          .on(
            'state_changed',
            (snapshot) => {
              // console.log(snapshot);
            },
            (error) => {
              alert(error.message);
              setUploadLoading(false);
            },
            async () => {
              // error
              await storage()
                .ref(`${docName}/`)
                .child(image.name)
                .getDownloadURL()
                .then(async (url) => {
                  imagesUrls = [...imagesUrls, url];
                  if (imagesUrls.length === images.length) {
                    uploadFireStore(imagesUrls, imagesName);
                  }
                  await getPrefectImages();
                })
                .catch((err) => {
                  setUploadLoading(false);
                  alert(err.message);
                });
            }
          );
      });
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Upload FireStore
  const uploadFireStore = (url, names) => {
    prefectImagesRef
      .update({
        images: [...prefectImages.images, ...url],
        imageNames: [...prefectImages.imageNames, ...names],
      })
      .then(() => {
        setUploadLoading(false);
        alert('Updated');
      })
      .catch((err) => {
        setUploadLoading(false);
        alert(err.message);
      });
  };

  // Update Result
  const uplodaImages = async (e) => {
    e.preventDefault();
    try {
      if (images?.length === 0) {
        alert('Please Fill All the Fields');
      } else {
        setUploadLoading(true);
        uploadToStorage();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Remove Images
  const removeImage = (i) => {
    setImages(images.filter((image, index) => index !== i));
  };

  // Delete Images
  const deleteImages = async (imageName, image) => {
    if (window.confirm('Are You Sure')) {
      deleteFormFireStore(imageName, image);
      deleteFromStorage(imageName);
      await getPrefectImages();
    }
  };

  // Delete From Firebase Storage
  const deleteFromStorage = async (imageName) => {
    console.log(`${docName}/${imageName}`);
    try {
      await storage().ref(`${docName}/`).child(imageName).delete();
    } catch (err) {
      alert(err.message);
    }
  };

  const fireStoreRef = fireStore().collection('academaic_staff');

  // Delete From FireStore
  const deleteFormFireStore = async (imageName, image) => {
    try {
      await fireStoreRef.doc(docName).update({
        imageNames: prefectImages.imageNames.filter(
          (name) => name !== imageName
        ),
        images: prefectImages.images.filter((im) => im !== image),
      });
      alert('Deleted');
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      {loading && <LoadingComponent />}
      {!loading && prefectImages?.length === 0 && (
        <h1>No Prefect Images Found</h1>
      )}
      {prefectImages?.images.map((image, index) => (
        <div key={index}>
          <Image src={image} alt={image} style={{ margin: 'auto' }} />
          <br />
          {userId && (
            <Icon
              style={{
                textAlign: 'center',
                margin: 'auto',
                alignSelf: 'center',
                cursor: 'pointer',
                color: 'red',
              }}
              size='huge'
              name='trash'
              onClick={() =>
                deleteImages(
                  prefectImages.imageNames[index],
                  prefectImages.images[index]
                )
              }
            />
          )}
          <br />
        </div>
      ))}
      <br />
      {userId && (
        <>
          <Icon
            style={{
              textAlign: 'center',
              margin: 'auto',
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            size='huge'
            name='edit'
            onClick={() => setEdit(!edit)}
          />
        </>
      )}
      <br />
      <br />
      {userId && edit && (
        <Form onSubmit={(e) => uplodaImages(e)}>
          <Form.Field>
            <Input
              type='file'
              placeholder='Year'
              onChange={(e) => selectUploadImages(e.target.files)}
              multiple
              accept='image/*'
            />
          </Form.Field>
          <Button color='green' loading={uploadLoading}>
            Add Image
          </Button>
        </Form>
      )}
      <div className='image_div'>
        {images.map((image, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <img src={URL.createObjectURL(image)} alt='slide' />
            {userId && (
              <Icon
                style={{
                  width: 40,
                  color: 'red',
                  cursor: 'pointer',
                }}
                name='remove'
                size={'big'}
                onClick={() => removeImage(index)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.firebase?.auth?.uid,
  };
};
export default connect(mapStateToProps)(PrefectImages);
