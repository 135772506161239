/* eslint-disable no-restricted-globals */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Icon } from "semantic-ui-react";
import { fireStore, storage } from "../../../../../config/firebase";

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

const SinglePrinciple = ({ principle, type }) => {
  const fireStoreRef = fireStore()
    .collection("academaic_staff")
    .doc(type)
    .collection(type);

  const classes = useStyles();

  // Delete From FireStore
  const deleteFormFireStore = async () => {
    try {
      await fireStoreRef.doc(principle.id).delete();
      alert("Deleted");
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From Firebase Storage
  const deleteFromStorage = () => {
    const deleteStorageRef = storage()
      .ref(`academic/${type}`)
      .child(principle.imageName);

    // Delete the file
    deleteStorageRef
      .delete()
      .then(() => {
        alert("Deleted");
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const deleteImage = async () => {
    if (confirm("Are you Sure?")) {
      await deleteFormFireStore();
      await deleteFromStorage();
    }
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={principle?.imageUrl}
          title={principle?.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {principle.name}
          </Typography>
          {/*<Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <Icon
            name="trash"
            size="large"
            style={{
              color: "darkRed"
            }}
            onClick={() => deleteImage()}
          />
        </Button>
      </CardActions>
    </Card>
  );
};

export default SinglePrinciple;
