import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC52brUc9PFaLjfyWf2b-hUZ3S8_foEx1s",
  authDomain: "school-web-f5211.firebaseapp.com",
  databaseURL: "https://school-web-f5211.firebaseio.com",
  projectId: "school-web-f5211",
  storageBucket: "school-web-f5211.appspot.com",
  messagingSenderId: "469184045652",
  appId: "1:469184045652:web:f40316d42a2687694683c1"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
export const auth = firebase.auth;
export const storage = firebase.storage;
export const database = firebase.database;
export const fireStore = firebase.firestore;
