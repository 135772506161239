import React from "react";
import { Route } from "react-router-dom";

import HomePage from "./screens/Home/HomePage";
import Login from "./screens/Auth/Login/Login";
// import Register from "./screens/Auth/Register/Register";
import Admin from "./screens/Admin/Admin";
import AddSlideShowImages from "./screens/Admin/AddSlideShowImages/AddSlideShowImages";
import RequireAuth from "./components/protectedComponents/ProtectAuth";
import AllSlideImages from "./screens/Admin/AddSlideShowImages/AllSlideImages/AllSlideImages";
import AddAssignments from "./screens/Admin/AddAssignments/AddAssignments";
import Assignments from "./screens/Assignments/Assignments";
import GradeAssignments from "./screens/GradeAssignments/GradeAssignments";
import AssignmentsLists from "./screens/AssignmentsLists/AssignmentsLists";
import AddNews from "./screens/Admin/AddNews/AddNews";
import History from "./screens/History/History";
import AddPrinciple from "./screens/Admin/AddPrinciple/AddPrinciple";
import Profile from "./screens/AboutUs/Profile/Profile";
import BasicInformations from "./screens/AboutUs/BasicInfo/BasicInformations";
import AddPrincipleMessageAndImage from "./screens/Admin/AddPrincipleMessageAndImage/AddPrincipleMessageAndImage";
import Gallery from "./screens/Gallery/Gallery";
import AddEventGalleryImages from "./screens/Admin/AddEventGalleryImages/AddEventGalleryImages";
import ContactUs from "./screens/ContactUs/ContactUs";
// import Principles from "./screens/History/Principles/Principles";
import Principles2 from "./screens/History/Principles/Principles2";
import Administration from "./screens/Administration/Administration";
import Principle from "./screens/Administration/Principle/Principle";
import DuputyPrinciples from "./screens/Administration/DuputyPrinciples/DuputyPrinciples";
import AddAcademicStaff from "./screens/Admin/AddAcademicStaff/AddAcademicStaff";
import AcademicStaff from "./screens/Administration/AcademicStaff/AcademicStaff";
import PrefectGuild from "./screens/Administration/PrefectGuild/PrefectGuild";
import GCEOL from "./screens/Academic/GCEOL/GCEOL";
import GCEAL from "./screens/Academic/GCEAL/GCEAL";
import Clubs from "./screens/ExtraCurricular/Clubs/Clubs";
import Sports from "./screens/ExtraCurricular/Sports/Sports";
import AddSportAndSociety from "./screens/Admin/AddSportAndSociety/AddSportAndSociety";

const Routes = () => {
  return (
    <>
      <Route path="/" exact component={HomePage} />
      <Route path="/login" exact component={Login} />
      {/*<Route path="/register" exact component={Register} /> */}
      <Route path="/admin" exact component={RequireAuth(Admin)} />
      <Route
        path="/addSlideShow"
        exact
        component={RequireAuth(AddSlideShowImages)}
      />
      <Route
        path="/allSlideImages"
        exact
        component={RequireAuth(AllSlideImages)}
      />
      <Route
        path="/addPrincipleMessage"
        exact
        component={RequireAuth(AddPrincipleMessageAndImage)}
      />
      <Route
        path="/addSociety"
        exact
        component={RequireAuth(AddSportAndSociety)}
      />
      <Route
        path="/addEventGallery"
        exact
        component={RequireAuth(AddEventGalleryImages)}
      />
      <Route
        path="/addAcademicStaff"
        exact
        component={RequireAuth(AddAcademicStaff)}
      />

      <Route
        path="/addAssignments"
        exact
        component={RequireAuth(AddAssignments)}
      />
      <Route path="/addNews" exact component={RequireAuth(AddNews)} />
      <Route path="/addPrinciple" exact component={RequireAuth(AddPrinciple)} />
      <Route path="/assignments" exact component={Assignments} />
      <Route path="/academicStaff" exact component={AcademicStaff} />
      <Route path="/gceOL" exact component={GCEOL} />
      <Route path="/gceAL" exact component={GCEAL} />
      <Route path="/clubs" exact component={Clubs} />
      <Route path="/sports" exact component={Sports} />
      <Route path="/prefectGuild" exact component={PrefectGuild} />
      <Route path="/history" exact component={History} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/basicInfo" exact component={BasicInformations} />
      <Route path="/gallery" exact component={Gallery} />
      <Route path="/contactUs" exact component={ContactUs} />
      <Route path="/principal" exact component={Principle} />
      <Route path="/duputyPrincipals" exact component={DuputyPrinciples} />
      <Route path="/pastPrincipals" exact component={Principles2} />
      <Route path="/administration" exact component={Administration} />
      <Route path="/assignment/:grade" exact component={GradeAssignments} />
      <Route
        path="/assign/:grade/:subject"
        exact
        component={AssignmentsLists}
      />
    </>
  );
};

export default Routes;
