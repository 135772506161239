import React from "react";
import { Grid } from "@material-ui/core";
import LeftImages from "./LeftImages";

const Messages = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} lg={8}>
      
        <p className="history_message" style={{ fontSize: 20 }}>
        <h1 style={{ textAlign: "left" }}>History</h1>
          Ananda Balika Vidyalaya was inaugurated on 25th January in 1925 at
          Campbell Place in Borella. It was the desire of Srimath Anagarika
          Dharmapala to establish a school to train the school girls in keeping
          with the Buddhist moral values. He donated his house called “Victor
          House” and a land at Maligakanda ,Colombo to establish a Buddhist
          girls school.
          <br />
          <br />
          Following his instructions Mr.P.De.S. Kularathne, The founder of
          Ananda College ,took initiatives to establish Ananda Balika
          Vidyalaya.His wife Mrs.Hilda Kularathne was the first principal of the
          school.
          <br />
          <br />
          During the World War II ,the school premises was highly damaged.
          Mr.P.De.S. Kularathne instructed one of his staff members
          ,Ms.S.Rathnapala (later Ms.Somi Keerthisena ) to re-open the school as
          a part of Maha Bodhi Vidyalaya at Foster Lane that belonged to Maha
          Bodhi society . To accommodate the increasing number of students ,it
          moved to its present premises ,the land and “Victor House” at
          Maligakanda donated by srimath Anagarika Dharmapala.
          <br />
          <br />
          It was a school for girls and conducted classes from kindergarten to
          Grade 3.The students were sent to Ananda College for studies in higher
          studies. Later, it began classes from kindergarten to London
          Matriculation form. By the time the school re-opened at the present
          premises at Maligakanda, Colombo on 12th May 1943, There were 20
          students and 3 teachers.
        </p>
      </Grid>
      <Grid item xs={12} sm={12} lg={3} xl={4}>
        <LeftImages />
      </Grid>
    </Grid>
  );
};

export default Messages;
