import { createReducer } from "../util/reducerUtil";
import {
  ADD_SLIDE_SHOW_IMAGES,
  REMOVE_SLIDE_SHOW_IMAGE,
  GET_SLIDE_SHOW_IMAGES
} from "../../types";

const initialState = [];

const addSlideShowImages = (state, { images }) => {
  return [...state, ...images];
};

const getSlideShowImages = (state, { images }) => {
  return images;
};

const removeSlideShowImages = (state, { id }) => {
  return state.filter(image => image.id !== id);
};

export default createReducer(initialState, {
  [ADD_SLIDE_SHOW_IMAGES]: addSlideShowImages,
  [GET_SLIDE_SHOW_IMAGES]: getSlideShowImages,
  [REMOVE_SLIDE_SHOW_IMAGE]: removeSlideShowImages
});
