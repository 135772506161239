import React from "react";
import MarkUp from "../MarkUp";

const AboutUsMarkUp = ({ children }) => {
  return (
    <MarkUp>
      <div className="container">{children}</div>
    </MarkUp>
  );
};

export default AboutUsMarkUp;
