/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MarkUp from "../../MarkUp";
import { fireStore, storage } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Image, Icon, Form, Input, Button } from "semantic-ui-react";

const gceALRef = fireStore().collection("academic").doc("alResults");

const GCEAL = ({ userId }) => {
  const [results, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState([]);

  // Get Data
  const getData = async () => {
    try {
      setResult(await (await gceALRef.get()).data());
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete From Firebase Storage
  const deleteFromStorage = async () => {
    const deleteStorageRef = storage().ref(`results/`);
    try {
      results.imageNames.map(image => {
        deleteStorageRef.child(image).delete();
      });
    } catch (err) {
      alert(err.message);
    }
  };

  // Add Images to State
  const selectUploadImages = files => {
    if (Array.from(files).length + images.length > 5) {
      alert("Please Select Only One Image");
      const filterImages = Array.from(files).filter(
        (image, index) => index < 5
      );
      const allImages = [...images, ...filterImages];
      const filterAllImages = allImages.filter((images, index) => index < 5);
      setImages(filterAllImages);
    } else {
      setImages([...images, ...Array.from(files)]);
    }
  };

  // Upload To Firebase Storage
  const uploadToStorage = async () => {
    let imagesUrls = [];
    let imagesName = [];
    try {
      images.map(image => {
        imagesName = [...imagesName, image.name];
        storage()
          .ref(`results/${image.name}`)
          .put(image)
          .on(
            "state_changed",
            snapshot => {
              // console.log(snapshot);
            },
            error => {
              alert(error.message);
              setUploadLoading(false);
            },
            async () => {
              // error
              await storage()
                .ref(`results/`)
                .child(image.name)
                .getDownloadURL()
                .then(async url => {
                  imagesUrls = [...imagesUrls, url];
                  if (imagesUrls.length === images.length) {
                    uploadFireStore(imagesUrls, imagesName);
                  }
                })
                .catch(err => {
                  setUploadLoading(false);
                  alert(err.message);
                });
            }
          );
      });
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Upload FireStore
  const uploadFireStore = (url, names) => {
    gceALRef
      .update({
        year: results.year,
        images: url,
        imageNames: names
      })
      .then(() => {
        setUploadLoading(false);
        alert("Updated");
      })
      .catch(err => {
        setUploadLoading(false);
        alert(err.message);
      });
  };

  // Update Result
  const updateResult = async e => {
    e.preventDefault();
    try {
      if (!images?.length === 0) {
        alert("Please Fill All the Fields");
      } else {
        deleteFromStorage();
        setUploadLoading(true);
        uploadToStorage();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Remove Images
  const removeImage = i => {
    setImages(images.filter((image, index) => index !== i));
  };

  return (
    <MarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>A/L Results</h1>
        {loading && <LoadingComponent />}
        {!loading && !results && <h1>No Results Found</h1>}
        {results && (
          <>
            <h1>Year: {results?.year}</h1>
            {results?.images?.map(image => (
              <Image style={{ margin: "auto" }} src={image} />
            ))}
          </>
        )}
        {userId && (
          <Icon
            name="edit"
            size="large"
            style={{ cursor: "pointer" }}
            onClick={() => setEdit(!edit)}
          />
        )}
        <br />
        <br />
        {userId && edit && (
          <>
            <Form onSubmit={e => updateResult(e)}>
              <Form.Field>
                <Input
                  placeholder="Year"
                  value={results?.year}
                  onChange={e =>
                    setResult({
                      ...results,
                      year: e.target.value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Input
                  type="file"
                  placeholder="Year"
                  onChange={e => selectUploadImages(e.target.files)}
                  multiple
                  accept="image/*"
                />
              </Form.Field>
              <Button color="green" loading={uploadLoading}>
                Update Result
              </Button>
            </Form>
          </>
        )}
        <div className="image_div">
          {images.map((image, index) => (
            <div key={index} style={{ display: "flex" }}>
              <img src={URL.createObjectURL(image)} alt="slide" />
              <Icon
                style={{
                  width: 40,
                  color: "red",
                  cursor: "pointer"
                }}
                name="remove"
                size={"big"}
                onClick={() => removeImage(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </MarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};
export default connect(mapStateToProps)(GCEAL);
