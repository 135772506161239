import { createReducer } from "../util/reducerUtil";
import { LOGIN_USER, LOGOUT_USER, CHECK_AUTH_STATE } from "../../types";

const initialState = null;

const checkUsersAuthState = (state, payload) => {
  return payload.auth;
};

const loginUser = state => {
  return true;
};

const logoutUser = state => {
  return false;
};

export default createReducer(initialState, {
  [CHECK_AUTH_STATE]: checkUsersAuthState,
  [LOGIN_USER]: loginUser,
  [LOGOUT_USER]: logoutUser
});
