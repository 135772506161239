/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MarkUp from "../../MarkUp";
import { fireStore } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import SingleItem from "../SingleItem/SingleItem";

const Sports = () => {
  const sportsRef = fireStore().collection("sports");

  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get Clubs
  const getSports = async () => {
    let newSports = [];
    try {
      await (await sportsRef.get()).forEach(item => {
        newSports = [...newSports, { ...item.data(), id: item.id }];
      });
      setLoading(false);
      setSports(newSports);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getSports();
  }, []);

  return (
    <MarkUp>
      <div className="container">
        <div className='profile'>
        <center><h1 style={{ textAlign: "center",marginBottom:'2em' }} id='clubs'>Sports</h1></center>
        <div style={{ textAlign: "center" }}>
          {loading && <LoadingComponent />}
          {!loading && sports?.length === 0 && <h1>No Sports Found</h1>}
          <Grid container spacing={2}>
            {sports.map(sport => (
              <SingleItem
                key={sport.id}
                item={sport}
                collectionName={"sports"}
              />
            ))}
          </Grid>
        </div>
        </div>
      </div>
    </MarkUp>
  );
};

export default Sports;
