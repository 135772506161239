/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MarkUp from "../../MarkUp";
import { fireStore } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import SingleItem from "../SingleItem/SingleItem";

const Clubs = () => {
  const clubsRef = fireStore().collection("clubs");

  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get Clubs
  const getClubs = async () => {
    let newClubs = [];
    try {
      await (await clubsRef.orderBy("number").get()).forEach(item => {
        newClubs = [...newClubs, { ...item.data(), id: item.id }];
      });
      setLoading(false);
      const buddhistSociety = newClubs.filter(
        club => club.id === "buddhist"
      )[0];
      const otherClubs = newClubs.filter(club => club.id !== "buddhist");
      setClubs([buddhistSociety, ...otherClubs]);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getClubs();
  }, []);

  return (
    <MarkUp>
      <div className="container">
        <div className="profile">
          <center>
            <h1 style={{ textAlign: "center", marginBottom: "2em" }} id="clubs">
              Clubs
            </h1>
          </center>
          <div style={{ textAlign: "center" }}>
            {loading && <LoadingComponent />}
            {!loading && clubs?.length === 0 && <h1>No Clubs Found</h1>}
            <Grid container spacing={2}>
              {clubs.map(club => (
                <SingleItem
                  key={club.id}
                  item={club}
                  collectionName={"clubs"}
                />
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </MarkUp>
  );
};

export default Clubs;
