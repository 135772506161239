/* eslint-disable no-restricted-globals */
import { fireStore, storage } from "../config/firebase";

export const deleteFunctions = async (item, refName) => {
  try {
    if (confirm("Are You Sure?")) {
      deleteFromFireStore(item, refName);
      if (item.image) {
        deleteFormStorage(item.name, refName);
      }
    }
  } catch (err) {
    alert(err.message);
  }
};

// Delete From Storage
const deleteFormStorage = (name, refName) => {
  const deleteStorageRef = storage().ref(`/${refName}`).child(name);
  // Delete the file
  deleteStorageRef
    .delete()
    .then(() => {
      // File deleted successfully
      alert("Deleted");
    })
    .catch(err => {
      alert(err.message);
    });
};

// Delete From FireStore
const deleteFromFireStore = async (item, refName) => {
  try {
    await fireStore().collection(refName).doc(item.id).delete();
    alert("Deleted");
  } catch (err) {
    alert(err.message);
  }
};
