/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MarkUp from "../MarkUp";
import { fireStore } from "../../../config/firebase";
import Subjects from "./Subjects/Subjects";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";

const GradeAssignments = () => {
  const { grade } = useParams();

  const history = useHistory();

  const [subjects, setSubjects] = useState([]);

  const gradeNumber = parseInt(grade.split(" ")[1]);

  if (grade.split(" ")[0] !== "Grade") {
    history.push("/");
  }

  let queryLocation;
  if (gradeNumber === 12) {
    queryLocation = "grade_12_13";
  } else if (gradeNumber >= 10) {
    queryLocation = "grade_10_11";
  } else {
    queryLocation = "grade_6_9";
  }

  useEffect(() => {
    getGradeSubjects();
  }, [gradeNumber]);

  const getGradeSubjects = async () => {
    let dbSubjects = [];
    try {
      dbSubjects = await fireStore()
        .collection(`subjects`)
        .doc(queryLocation)
        .get();
      setSubjects(dbSubjects.data().subjects);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <MarkUp>
      <div className="container" style={{ textAlign: "center" }}>
        <h1 className="title">{grade} Assignments</h1>
        {subjects.length === 0 && <LoadingComponent />}
        <Grid container spacing={4}>
          {subjects.map(subject => (
            <Subjects key={subject} subject={subject} />
          ))}
        </Grid>
      </div>
    </MarkUp>
  );
};

export default GradeAssignments;
