import React, { useEffect, useState } from "react";
import SingleEvent from "./SingleEvent/SingleEvent";
import { fireStore } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const eventRef = fireStore().collection("events");

const Events = () => {
  // useEffect(() => {
  //   pageScroll();
  // }, []);

  // const pageScroll = () => {
  //   setInterval(() => {
  //     document.getElementById("auto_scroll").scrollBy(0, 50);
  //   }, 1000);
  // };

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    let evt = [];
    try {
      await (
        await eventRef.orderBy("updatedAt", "desc").limit(4).get()
      ).forEach(evet => {
        evt = [...evt, { ...evet.data(), id: evet.id }];
      });
      setEvents(evt);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <h1 style={{ textAlign: "center", color: "rgb(197, 199, 199)", fontFamily:'Playfair Display' }}>Latest Events</h1>
      <div style={{ height: "800px", overflow: "auto" }} id="auto_scroll">
        {loading && <LoadingComponent />}
        {!loading && events.length === 0 && <h1>No Event Found</h1>}
        {events.map(evt => (
          <div key={evt.id}>
            <SingleEvent evt={evt} />
            <br />
          </div>
        ))}
      </div>
    </>
  );
};

export default Events;
