/* eslint-disable no-restricted-globals */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import { Icon } from "semantic-ui-react";
import { deleteFunctions } from "../../../../../helpers/deleteFunctions";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

const SingleEvent = ({ evt, userId }) => {
  const classes = useStyles();

  const deleteEvent = () => {
    deleteFunctions(evt, "events");
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={<IconButton aria-label="settings"></IconButton>}
        title={evt.title}
        subheader={evt.date}
      />
      <CardMedia
        className={classes.media}
        image={evt.image}
        title={evt.title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {evt.description}
        </Typography>
      </CardContent>
      {userId && (
        <CardActions disableSpacing>
          <Button size="small" color="primary" onClick={() => deleteEvent()}>
            <Icon name="trash" size="large" style={{ color: "darkRed" }} />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(SingleEvent);
