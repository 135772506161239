import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/auth/auth.actions";
import { Icon, Button } from "semantic-ui-react";
import { Grid } from "@material-ui/core";
import "./Navbar.css";

const reduxFunction = {
  logoutUser,
};

const NavBar = ({ auth, logoutUser }) => {
  const showMenu = () => {
    document.querySelector(".main-menu").classList.toggle("show");
  };

  const links = [
    { path: "/", label: "Home" },
    {
      path: "/history",
      label: "History",
      sub: [
        {
          path: "/history",
          label: "History",
        },
        {
          path: "/pastPrincipals",
          label: "Principals",
        },
      ],
    },
    {
      path: "/profile",
      label: "About us",
      sub: [
        {
          path: "/profile",
          label: "Profile",
        },
        {
          path: "/basicInfo",
          label: "Info",
        },
      ],
    },
    {
      path: "/gceOL",
      label: "Academic",
      sub: [
        {
          path: "/gceOL",
          label: "GCE O/L",
        },
        {
          path: "/gceAL",
          label: "GCE A/L",
        },
      ],
    },
    {
      path: "/principal",
      label: "Administration",
      sub: [
        { path: "/principal", label: "Principal" },
        { path: "/duputyPrincipals", label: "Duputy Principals" },
        { path: "/academicStaff", label: "Academic Staff" },
        { path: "/prefectGuild", label: "Prefect Guild" },
      ],
    },
    {
      path: "/clubs",
      label: "Extra curricular",
      sub: [
        {
          path: "/clubs",
          label: "Clubs and Societies",
        },
        {
          path: "/sports",
          label: "Sports",
        },
      ],
    },
    { path: "/gallery", label: "Gallery" },
    { path: "/contactUs", label: "Contact us" },
    { path: "/assignments", label: "Assignments" },
  ];

  return (
    <div className="nav_bar">
      <div className="menu-btn">
        <Icon name="th" onClick={() => showMenu()} />
      </div>
      <nav className="main-nav">
        <img
          src="../../images/logo.png"
          alt="Microsoft"
          className="logo"
          style={{ height: 100 }}
        />

        <ul
          className="main-menu"
          style={{
            alignItems: "center",
            alignSelf: "center",
            marginBottom: 17,
          }}
        >
          <Grid container>
            {links.map(({ path, label, sub }, index) => (
              <li
                className="navLinks"
                key={index}
                style={{ margin: 10, marginBottom: 1 }}
              >
                <Link to={path} style={{ fontSize: 18 }}>
                  {label}
                </Link>
                <ul className="dropdown">
                  {sub &&
                    sub.map((menu, i) => (
                      <li className="navLinks" key={i}>
                        <Link
                          className="navLinksA"
                          to={menu.path}
                          style={{ fontSize: 15 }}
                        >
                          {menu.label}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </Grid>
          {auth && (
            <>
              <li>
                <Link to="/admin">Admin</Link>
              </li>
              <li>
                <Button color="red" onClick={() => logoutUser()}>
                  Logout
                </Button>
              </li>
            </>
          )}
        </ul>
      </nav>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, reduxFunction)(NavBar);
