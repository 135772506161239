import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

import "./AdminNav.css";

const links = [
  { path: "/addSlideShow", label: "Add SlideShow Images" },
  { path: "/addAssignments", label: "Add Assignments" },
  { path: "/addNews", label: "Add News or Events" },
  { path: "/addPrinciple", label: "Add Principle" },
  { path: "/addPrincipleMessage", label: "Add Principle Message" },
  { path: "/addEventGallery", label: "Add Event Images" },
  { path: "/addAcademicStaff", label: "Add Academic Staff" },
  { path: "/addSociety", label: "Add Society and Clubs" }
];

const AdminNavBar = () => {
  return (
    <ul className="main-menu main-nav admin-nav">
      <Grid container spacing={3}>
        {links.map(({ path, label }, index) => (
          <Grid sm={6} md={2} item key={index}>
            <li>
              <Link to={path}>{label}</Link>
            </li>
          </Grid>
        ))}
      </Grid>
    </ul>
  );
};

export default AdminNavBar;
