import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import MarkUp from "../../MarkUp";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: "inline"
    }
  })
);

const Principles2 = () => {
  const classes = useStyles();

  const principleList = [
    { name: "Mrs.Hilda Kualarathne", years: "1925-1932" },
    { name: "Mrs.Doreen Wickramasinghe", years: "1932-1936" },
    { name: "Mrs.Hilda Kualarathne", years: "1936-1940" },
    { name: "Mrs.Adlin De Serum", years: "1940-1942" },
    { name: "Mrs.Jude Cook", years: "1942-1943" },
    { name: "Mrs.Somie Keerthisinghe", years: "1944 – 1970" },
    { name: "Mrs.Professor Thilokasundari Kariyawasam", years: "1970-1972" },
    { name: "Mrs.Roopa Nanayakkara", years: "1988-2002" },
    { name: "Mrs.Rukmani Gunawardana", years: "1925-1932" },
    { name: "Mrs.Malani Ranawaka Herath", years: "2002-2013" },
    { name: "Mrs.Anoma Dahanayaka", years: "2013-2020" }
  ];

  return (
    <MarkUp>
      <div style={{ textAlign: "center", margin: "auto", width: "30vw" }}>
        <List className={classes.root}>
          {principleList.map(principle => (
            <React.Fragment key={principle.name}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={principle.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {principle.years}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </div>
    </MarkUp>
  );
};

export default Principles2;
