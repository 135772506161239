import React from "react";
import AboutUsMarkUp from "../AboutUsMarkUp";
import VisionAndMission from "./VisionAndMission/VisionAndMission";
import Flag from "./Flag/Flag";
import SchoolAnthem from "./SchoolAnthem/SchoolAnthem";
import Motto from "./Motto/Motto";
import Logo from "./Logo/Logo";

const Profile = () => {
  return (
    <AboutUsMarkUp>
      <br />
      <VisionAndMission />
      <Logo />
      <Flag />
      <Motto />
      <SchoolAnthem />
    </AboutUsMarkUp>
  );
};

export default Profile;
