/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminMarkUp from "../../AdminMarkUp";
import { fireStore } from "../../../../../config/firebase";

import "./AllSlideImages.css";
import LoadingComponent from "../../../../components/Loading/LoadingComponent";
import { Icon } from "semantic-ui-react";
import { deleteFunctions } from "../../../../../helpers/deleteFunctions";

const slideCollection = fireStore().collection("slide_images");

const AllSlideImages = () => {
  const [allImages, setAllImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllImages = async () => {
    let images = [];

    (await slideCollection.orderBy("createdAt", "desc").get()).forEach(doc => {
      images = [...images, { ...doc.data(), id: doc.id }];
    });

    setAllImages(images);
    setLoading(false);
  };

  useEffect(() => {
    getAllImages();
  }, []);

  const deleteImage = async image => {
    image.image = image.slideImage;
    deleteFunctions(image, "slide_images");
  };

  return (
    <AdminMarkUp>
      {!loading && allImages.length === 0 ? "No Images Found" : ""}
      {loading && (
        <div style={{ justifyContent: "center", alignContent: "center" }}>
          <LoadingComponent />
        </div>
      )}
      <div class="image-container">
        {allImages.map(image => (
          <>
            <img
              key={image.createdAt}
              src={image.slideImage}
              alt={image.createdAt}
            />
            <Icon
              onClick={() => deleteImage(image)}
              style={{ justifyContent: "center", cursor: "pointer" }}
              name="remove"
              size="big"
            />
          </>
        ))}
      </div>
    </AdminMarkUp>
  );
};

export default AllSlideImages;
