import React, { useState } from "react";
import { connect } from "react-redux";
import MarkUp from "../../MarkUp";
import {
  sendResetLink,
  loginUser
} from "../../../../redux/actions/auth/auth.actions";
import { Form, Segment, Button } from "semantic-ui-react";

const reduxFunctions = {
  sendResetLink,
  loginUser
};

const Login = ({ sendResetLink, loginUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [forget, setForget] = useState(false);
  const [fEmail, setFEmail] = useState("");

  const login = e => {
    e.preventDefault();
    if (!email || !password) {
      alert("Fill All the Fields");
    } else {
      setLoading(true);
      loginUser(email, password, setLoading);
    }
  };

  const sendResetPasswordLink = () => {
    sendResetLink(fEmail);
  };

  return (
    <MarkUp>
      <div className="container">
        <Form size="large" onSubmit={e => login(e)}>
          <Segment>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input
                placeholder="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Field>

            <Button fluid size="large" color="teal" loading={loading}>
              Login
            </Button>
          </Segment>
        </Form>
        <br />
        <div style={{ textAlign: "center" }}>
          <label
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => setForget(!forget)}
          >
            Forget Password
          </label>
        </div>
        {forget && (
          <>
            <Form
              onSubmit={() => sendResetPasswordLink()}
              style={{ textAlign: "center" }}
            >
              <Form.Field>
                <label>Email</label>
                <input
                  placeholder="email"
                  type="email"
                  value={fEmail}
                  onChange={e => setFEmail(e.target.value)}
                />
                <br />
                <br />
                <Button>Send Reset Link</Button>
              </Form.Field>
            </Form>
          </>
        )}
      </div>
    </MarkUp>
  );
};

export default connect(null, reduxFunctions)(Login);
