/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Icon } from "semantic-ui-react";
import { fireStore, storage } from "../../../../config/firebase";
import { connect } from "react-redux";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const SingleGallery = ({ evt, userId }) => {
  const classes = useStyles();

  // Delete Event
  const deleteEvent = async () => {
    try {
      if (confirm("Are you Sure?")) {
        deleteFromFireStore();
        deleteFormStorage();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From FireStore
  const deleteFromFireStore = async () => {
    try {
      await fireStore().collection("event_gallery").doc(evt.id).delete();
      alert("Deleted");
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From Storage
  const deleteFormStorage = () => {
    let namesList = [];
    evt.names.map(name => {
      const deleteStorageRef = storage().ref("/event_gallery").child(name);
      namesList = [...namesList, name];
      deleteStorageRef
        .delete()
        .then(() => {})
        .catch(err => {
          alert(err.message);
        });
    });
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={4}>
      <Card className={classes.root}>
        <CardHeader title={evt?.title} subheader={evt?.date} />
        <CardMedia
          className={classes.media}
          image={evt?.images[0]}
          title={evt?.title}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {evt?.description}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <a href={evt.fbLink} target="__blank">
              More Images
            </a>
          </Typography>
        </CardContent>
        {userId && (
          <Icon
            onClick={() => deleteEvent()}
            name="trash"
            style={{
              color: "darkRed",
              cursor: "pointer",
              margin: 10
            }}
            size="large"
          />
        )}
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(SingleGallery);
