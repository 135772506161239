import React from "react";
import { Provider } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import configureStore from "../redux/store/configureStore";
import Routes from "./Routes";
import { checkAuthState } from "../redux/actions/auth/auth.actions";

// Semantic UI
import "semantic-ui-css/semantic.min.css";

const store = configureStore;

store.dispatch(checkAuthState());

const App = ({ location }) => {
  return (
    <Provider store={store}>
      <Switch key={location.key}>
        <Routes />
      </Switch>
    </Provider>
  );
};

export default withRouter(App);
