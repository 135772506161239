import React from "react";
import MarkUp from "../MarkUp";
import AdminNavBar from "./AdminNavBar/AdminNavBar";

const AdminMarkUp = ({ children }) => {
  return (
    <MarkUp>
      <div className="container">
        <AdminNavBar />
        {children}
      </div>
    </MarkUp>
  );
};

export default AdminMarkUp;
