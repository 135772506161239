import React from "react";
import MarkUp from "../MarkUp";
import Messages from "./Messages/Messages";

import "./History.css";

const History = () => {
  return (
    <MarkUp>
      <div className="container">
        <br />
        <Messages />
      </div>
    </MarkUp>
  );
};

export default History;
