import React, { useState, useEffect } from "react";
import AdministrationMarkUp from "../AdministrationMarkUp";
import { fireStore, storage } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { Image, Form, Input, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

const principleRef = fireStore().collection("academaic_staff").doc("principle");

const Principle = ({ userId }) => {
  const [principle, setPrinciple] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    getPrincipleDate();
  }, []);

  // Get Principle Data
  const getPrincipleDate = async () => {
    try {
      setPrinciple(await (await principleRef.get()).data());
      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Delete From Storage
  const deleteFormStorage = () => {
    const deleteStorageRef = storage()
      .ref("/academic/principle")
      .child(principle?.imageName);
    // Delete the file
    deleteStorageRef
      .delete()
      .then(() => {
        // File deleted successfully
        // alert("Previous Image Deleted Deleted");
      })
      .catch(err => {
        alert(err.message);
      });
  };

  // Update Principle Data
  const updatePrincipleData = async url => {
    try {
      await principleRef.update({
        name: principle?.name,
        imageName: image ? image.name : null,
        image: url ? url : principle?.image
      });
      alert("Updated");
    } catch (err) {
      setUploadLoading(false);
      alert(err.message);
    }
  };

  // Upload Image To FireStore
  const uploadToStorage = async () => {
    try {
      storage()
        .ref(`academic/principle/${image.name}`)
        .put(image)
        .on(
          "state_changed",
          snapshot => {
            // console.log(snapshot);
          },
          error => {
            alert(error.message);
            setUploadLoading(false);
          },
          async () => {
            // error
            await storage()
              .ref(`academic/principle`)
              .child(image.name)
              .getDownloadURL()
              .then(async url => {
                updatePrincipleData(url);
              })
              .catch(err => {
                setUploadLoading(false);
                alert(err.message);
              });
          }
        );
    } catch (err) {
      alert(err.message);
    }
  };

  // Submit
  const onSubmit = e => {
    e.preventDefault();
    if (image) {
      deleteFormStorage();
      uploadToStorage();
    } else {
      setUploadLoading(true);
      updatePrincipleData(null);
    }
  };

  return (
    <AdministrationMarkUp>
      <div
        className="container"
        style={{
          textAlign: "center",
          margin: "auto"
        }}
      >
        <br />
        <h1>Principal</h1>
        <br />

        {loading && <LoadingComponent />}
        {principle && (
          <>
            <Image
              style={{ margin: "auto", width: "50vw" }}
              src={principle?.image}
              alt={principle?.name}
            />
            <h1>{principle?.name}</h1>
          </>
        )}

        {userId && (
          <Icon
            name="edit"
            size="large"
            style={{ cursor: "pointer" }}
            onClick={() => setEditOpen(!editOpen)}
          />
        )}
        <br />
        <br />
        {userId && editOpen && (
          <>
            <Form onSubmit={e => onSubmit(e)}>
              <Form.Field>
                <Input
                  type="text"
                  value={principle?.name}
                  onChange={e =>
                    setPrinciple({
                      ...principle,
                      name: e.target.value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Input
                  type="file"
                  multiple={false}
                  accept="image/*"
                  onChange={e => setImage(e.target.files[0])}
                />
              </Form.Field>
              <Button loading={uploadLoading} type="submit" color="green">
                Edit Principle
              </Button>
            </Form>
          </>
        )}
      </div>
    </AdministrationMarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};
export default connect(mapStateToProps)(Principle);
