/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const RequireAuth = ComposedComponent => {
  const ProtectAuth = props => {
    const history = useHistory();

    useEffect(() => {
      checkIsAuth();
    }, [props?.auth]);

    // Check is Authenticated
    const checkIsAuth = () => {
      if (props?.auth === false) {
        history.push("/login");
      }
    };

    return <ComposedComponent {...props} />;
  };

  const mapStateToProps = state => {
    return {
      auth: state.auth
    };
  };

  return connect(mapStateToProps)(ProtectAuth);
};

export default RequireAuth;
