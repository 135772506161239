import React, { useState, useEffect } from "react";
import AdministrationMarkUp from "../AdministrationMarkUp";
import { fireStore } from "../../../../config/firebase";
import Duputy from "./Duputy/Duputy";
import Assistant from "./Assistant/Assistant";

const duputyPrinciplesRef = fireStore()
  .collection("academaic_staff")
  .doc("duputy_principles")
  .collection("duputy_principles");
const assistantPrinciplesRef = fireStore()
  .collection("academaic_staff")
  .doc("assistant_principles")
  .collection("assistant_principles");

const DuputyPrinciples = () => {
  const [dupytyPrinciples, setDupytyPrinciples] = useState([]);
  const [assistantPrinciples, setAssistantPrinciples] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPrincipleDate();
  }, []);

  // Get Principle Data
  const getPrincipleDate = async () => {
    let duputyPrinciples = [];
    let assPrinciples = [];

    try {
      await (await duputyPrinciplesRef.get()).forEach(item => {
        duputyPrinciples = [
          ...duputyPrinciples,
          { ...item.data(), id: item.id }
        ];
      });
      setDupytyPrinciples(duputyPrinciples);
      await (await assistantPrinciplesRef.get()).forEach(item => {
        assPrinciples = [...assPrinciples, { ...item.data(), id: item.id }];
      });
      setAssistantPrinciples(assPrinciples);
      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  return (
    <AdministrationMarkUp>
      <div
        className="container"
        style={{
          textAlign: "center",
          margin: "auto"
        }}
      >
        <br />
        <h1>Duputy and Assistant Principles</h1>
        <br />
        <Duputy principles={dupytyPrinciples} loading={loading} />
        <Assistant principles={assistantPrinciples} loading={loading} />
      </div>
    </AdministrationMarkUp>
  );
};

export default DuputyPrinciples;
