import { combineReducers } from "redux";

import asyncAction from "./async/asyncReducer";
import auth from "./auth/auth.reducer";
import slideShow from "./slideShow/slideShow.reducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  async: asyncAction,
  auth,
  slideShow
});

export default rootReducer;
