/* eslint-disable array-callback-return */
import React, { useState } from "react";
import AdminMarkUp from "../AdminMarkUp";
import { Icon, Button, Form, Input, TextArea } from "semantic-ui-react";
import { storage, fireStore } from "../../../../config/firebase";

const eventGalleryRef = fireStore().collection("event_gallery");

const AddEventGalleryImages = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [fbLink, setFbLink] = useState("");

  // Add Images to State
  const selectUploadImages = files => {
    if (Array.from(files).length + images.length > 1) {
      alert("Please Select Only One Image");
      const filterImages = Array.from(files).filter(
        (image, index) => index < 1
      );
      const allImages = [...images, ...filterImages];
      const filterAllImages = allImages.filter((images, index) => index < 1);
      setImages(filterAllImages);
    } else {
      setImages([...images, ...Array.from(files)]);
    }
  };

  // Remove Images
  const removeImage = i => {
    setImages(images.filter((image, index) => index !== i));
  };

  // Upload Images
  const uploadEvents = () => {
    if (!title || !description || !date || images.length === 0 || !fbLink) {
      alert("Please Fill All the Fields");
    } else {
      setLoading(true);
      uploadToStorage();
    }
  };

  // Upload To Firebase Storage
  const uploadToStorage = async () => {
    try {
      let imageUrls = [];
      let names = [];
      await images.map(file => {
        names = [...names, file.name];
        storage()
          .ref(`event_gallery/${file.name}`)
          .put(file)
          .on(
            "state_changed",
            snapshot => {
              // console.log(snapshot);
            },
            error => {
              alert(error.message);
            },
            async () => {
              // error
              await storage()
                .ref(`event_gallery/`)
                .child(file.name)
                .getDownloadURL()
                .then(async url => {
                  imageUrls = [...imageUrls, url];

                  if (imageUrls.length === images.length) {
                    uploadFireStore(names, imageUrls);
                  }
                })
                .catch(err => {
                  setLoading(false);
                  alert(err.message);
                });
            }
          );
      });
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Upload FireStore
  const uploadFireStore = (names, images) => {
    const time = new Date().getTime();
    const dates = new Date(time);
    eventGalleryRef
      .add({
        images,
        title,
        description,
        fbLink,
        date,
        createdAt: dates.toString(),
        updatedAt: Date.now(),
        names
      })
      .then(() => {
        setLoading(false);
        alert("Uploaded");
      })
      .catch(err => {
        setLoading(false);
        alert(err.message);
      });
  };

  return (
    <AdminMarkUp>
      <h1 style={{ textAlign: "center" }}>Add Event Gallery Images</h1>
      <Form style={{ textAlign: "center" }} onSubmit={e => uploadEvents(e)}>
        <Form.Field>
          <Input
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
            placeholder="Title"
          />
        </Form.Field>
        <Form.Field>
          <Input
            value={date}
            onChange={e => {
              setDate(e.target.value);
            }}
            placeholder="Date"
          />
        </Form.Field>
        <Form.Field>
          <Input
            value={fbLink}
            onChange={e => {
              setFbLink(e.target.value);
            }}
            placeholder="Facebook Link"
          />
        </Form.Field>
        <Form.Field>
          <TextArea
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
            placeholder="Description"
          />
        </Form.Field>
        <div className="dropper">
          <input
            className="image_upload"
            type="file"
            onChange={e => selectUploadImages(e.target.files)}
            multiple
            accept="image/*"
          />
          <span>Drag Files Here</span>
        </div>

        <Button loading={loading} color="green" type="submit">
          Upload
        </Button>
      </Form>
      <div className="image_div">
        {images.map((image, index) => (
          <div key={index} style={{ display: "flex" }}>
            <img src={URL.createObjectURL(image)} alt="slide" />
            <Icon
              style={{ width: 40, color: "red", cursor: "pointer" }}
              name="remove"
              size={"big"}
              onClick={() => removeImage(index)}
            />
          </div>
        ))}
      </div>
    </AdminMarkUp>
  );
};

export default AddEventGalleryImages;
