import React, { useState } from "react";
import AdminMarkUp from "../AdminMarkUp";
import { Form, Select, Input, Button } from "semantic-ui-react";
import { fireStore, storage } from "../../../../config/firebase";

const types = [
  {
    key: "Duputy Principle",
    text: "Duputy Principle",
    value: "duputy_principles"
  },
  {
    key: "Assistant Principle",
    text: "Assistant Principle",
    value: "assistant_principles"
  },
  { key: "Academic Staff", text: "Academic Staff", value: "academic_staff" },
  { key: "Prefect Guild", text: "Prefect Guild", value: "prefect_guild" }
];

const AddAcademicStaff = () => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");

  // Upload Data
  const uploadStaff = () => {
    try {
      if (!name || !type) {
        alert("Please Select Name and Type");
        return;
      } else if (
        type === "duputy_principles" ||
        type === "assistant_principles"
      ) {
        if (!image) {
          alert("Please Select a Image");
        } else {
          setLoading(true);
          uploadToStorage();
        }
        return;
      } else {
        setLoading(true);
        uploadToFireStore(null);
      }
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  const uploadToStorage = async () => {
    try {
      storage()
        .ref(`academic/${type}/${image.name}`)
        .put(image)
        .on(
          "state_changed",
          snapshot => {
            // console.log(snapshot);
          },
          err => {
            alert(err.message);
          },
          async () => {
            // error
            await storage()
              .ref(`academic/${type}/`)
              .child(image.name)
              .getDownloadURL()
              .then(async url => {
                uploadToFireStore(url);
              })
              .catch(err => alert(err.message));
          }
        );
    } catch (err) {
      alert(err.message);
    }
  };

  const uploadToFireStore = async url => {
    const time = new Date().getTime();
    const dates = new Date(time);
    try {
      await fireStore()
        .collection("academaic_staff")
        .doc(type)
        .collection(type)
        .add({
          name,
          createdAt: dates.toString(),
          updatedAt: Date.now(),
          imageUrl: url ? url : null,
          imageName: url ? image.name : null
        });
      setLoading(false);
      alert("Uploaded");
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  return (
    <AdminMarkUp>
      <div style={{ textAlign: "center" }}>
        <br />
        <h1>Add Academic Staff</h1>
        <Form onSubmit={e => uploadStaff(e)}>
          <Form.Field>
            <Select
              value={type}
              onChange={(e, data) => {
                setType(data.value);
              }}
              placeholder="Select Staff Type"
              options={types}
              multiple={false}
            />
          </Form.Field>

          <Form.Field>
            <Input
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              placeholder="Name"
            />
          </Form.Field>

          {(type === "duputy_principles" ||
            type === "assistant_principles") && (
            <Form.Field>
              <Input
                type="file"
                onChange={e => setImage(e.target.files[0])}
                multiple={false}
              />
            </Form.Field>
          )}
          <Button type="submit" color="green" loading={loading}>
            Add
          </Button>
        </Form>
      </div>
    </AdminMarkUp>
  );
};

export default AddAcademicStaff;
