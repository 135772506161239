/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Image, Icon, Input, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { storage, fireStore } from "../../../../config/firebase";

const SingleItem = ({ item, userId, collectionName }) => {
  const [data, setData] = useState(item);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [newMember, setNewMember] = useState("");

  // Add New Member
  const addNewMember = async () => {
    let allMember = data?.members;
    allMember = [...allMember, newMember];
    await setData({ ...data, members: allMember });
    editData({ ...data, members: allMember });
  };

  // Remove Members
  const removeMembers = index => {
    if (confirm("Are You Sure?")) {
      let newData = data;
      let allMembers = data?.members;
      allMembers = allMembers.filter((mem, i) => i !== index);
      newData = { ...newData, members: allMembers };
      setData(newData);
      uploadFireStore(null, newData);
    }
  };

  // Render Buddhist Society
  const renderBuddhistSociety = () => {
    if (data?.id === "buddhist") {
      return (
        <>
          <p>Teacher-in-charge</p>
          <ul>
            <li>President: {data?.President}</li>
            {userId && edit && (
              <Input
                value={data?.President}
                onChange={e => setData({ ...data, President: e.target.value })}
              />
            )}
            <li>Secretory: {data?.Secretory}</li>
            {userId && edit && (
              <Input
                value={data?.Secretory}
                onChange={e => setData({ ...data, Secretory: e.target.value })}
              />
            )}
            <li>Treasurer: {data?.Treasurer}</li>
            {userId && edit && (
              <Input
                value={data?.Treasurer}
                onChange={e => setData({ ...data, Treasurer: e.target.value })}
              />
            )}
            <br />
            <br />
            {userId && edit && (
              <Input
                type="file"
                multiple={false}
                accept="image/*"
                onChange={e => setImage(e.target.files[0])}
              />
            )}
            <br />
            {userId && (
              <Icon
                name="edit"
                style={{ cursor: "pointer" }}
                onClick={() => setEdit(!edit)}
              />
            )}
            <br />
            <br />
            {userId && edit && (
              <Button
                loading={loading}
                onClick={() => editData(null)}
                color="green"
              >
                Edit
              </Button>
            )}
          </ul>
        </>
      );
    } else {
      return null;
    }
  };

  // Render Other Clubs
  const renderOtherSocieties = () => {
    return (
      <>
        <p>Teacher-in-charge</p>
        <ul>
          {data?.members.map((itm, index) => (
            <div key={index}>
              <li>
                {itm}{" "}
                {userId && (
                  <Icon
                    name="delete"
                    style={{ color: "darkRed", cursor: "pointer" }}
                    onClick={() => removeMembers(index)}
                  />
                )}{" "}
              </li>
              {userId && edit && (
                <Input
                  value={itm}
                  onChange={e => {
                    let allMembers = data?.members;
                    allMembers[index] = e.target.value;
                    setData({
                      ...data,
                      members: allMembers
                    });
                  }}
                />
              )}
            </div>
          ))}
        </ul>
        {userId && edit && (
          <Input
            type="file"
            multiple={false}
            accept="image/*"
            onChange={e => setImage(e.target.files[0])}
          />
        )}
        {userId && (
          <Icon
            name="edit"
            style={{ cursor: "pointer" }}
            onClick={() => setEdit(!edit)}
          />
        )}
        {userId && edit && (
          <Button
            loading={loading}
            onClick={() => editData(null)}
            color="green"
          >
            Edit
          </Button>
        )}
        {userId && (
          <Icon
            name="add"
            style={{ cursor: "pointer" }}
            onClick={() => setAddMember(!addMember)}
          />
        )}
        {userId && addMember && (
          <Input onChange={e => setNewMember(e.target.value)} />
        )}
        {userId && addMember && (
          <Button
            loading={loading}
            onClick={() => addNewMember()}
            color="green"
          >
            Edit
          </Button>
        )}
      </>
    );
  };

  // Delete Society
  const deleteSociety = async () => {
    try {
      if (window.confirm("Are you Sure")) {
        deleteFireStore();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From Fire Store
  const deleteFireStore = async () => {
    try {
      await fireStore().collection(collectionName).doc(item.id).delete();
      deleteFromStorage();
      alert("Deleted");
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete From Firebase Storage
  const deleteFromStorage = () => {
    const deleteStorageRef = storage()
      .ref(`${collectionName}/`)
      .child(item.imageName);

    // Delete the file
    deleteStorageRef
      .delete()
      .then(() => {
        // File deleted successfully

        alert("Deleted");
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const editData = data => {
    if (image) {
      setLoading(true);
      deleteFromStorage();
      uploadFireStorage(data);
    } else {
      setLoading(true);
      uploadFireStore(null, data);
    }
  };

  const uploadFireStorage = data => {
    storage()
      .ref(collectionName)
      .child(image.name)
      .put(image)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref(`${collectionName}/`)
            .child(image.name)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url, data);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };
  //    Update Store
  const uploadFireStore = (url, datas) => {
    //   const time = new Date().getTime();
    // const date = new Date(time);
    let updateData = datas ? datas : data;
    updateData.image = url ? url : data.image;
    updateData.imageName = image ? image.name : null;
    fireStore()
      .collection(collectionName)
      .doc(item.id)
      .update(updateData)

      .then(() => {
        alert("Updated");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <h1 style={{ textAlign: "center" }}>{data?.society_name}</h1>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} lg={6} xl={6}>
          <Image src={item.image} />
        </Grid>
        <Grid item xs={6} md={6} lg={6} xl={6}>
          {item.id === "buddhist"
            ? renderBuddhistSociety()
            : renderOtherSocieties()}
        </Grid>
        {userId && item.id !== "buddhist" && (
          <>
            <Icon
              name="trash"
              size="large"
              style={{
                color: "darkRed",
                cursor: "pointer"
              }}
              onClick={() => deleteSociety()}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};
export default connect(mapStateToProps)(SingleItem);
