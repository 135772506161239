import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "./template.css";

import App from "./app/App";

const rootEl = document.getElementById("root");

let render = () =>
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootEl
  );

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(App, () => {
    setTimeout(render);
  });
}

render();
// sjkhsdshdshd