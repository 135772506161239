import React from "react";
import ReactPlayer from "react-player";

import "./SchoolAnthem.css";

const SchoolAnthem = () => {
  return (
    <div className="profile">
      <center>
        <h1>School Anthem</h1>
        <ReactPlayer
          className="anthem"
          url="https://www.youtube.com/watch?v=95IVqa7jj_w"
        />
      </center>
    </div>
  );
};

export default SchoolAnthem;
