import React, { useState } from "react";
import { connect } from "react-redux";

import AdminMarkUp from "../AdminMarkUp";

import "./AddSlideShowImages.css";
import { Icon, Button } from "semantic-ui-react";
import { addSlideShowImages } from "../../../../redux/actions/slideShow/slideShow.action";
import SlideShowMarkUp from "./SlideShowMarkUp";

const reduxFunctions = {
  addSlideShowImages
};

const AddSlideShowImages = ({ addSlideShowImages }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Add Images to State
  const selectUploadImages = files => {
    setImages([...images, ...Array.from(files)]);
  };

  // Remove Images
  const removeImage = i => {
    setImages(images.filter((image, index) => index !== i));
  };

  // Upload Images
  const upload = () => {
    setLoading(true);
    addSlideShowImages(images, setLoading);
  };

  return (
    <AdminMarkUp>
      <SlideShowMarkUp>
        <h1>Add Slide Show Images</h1>
        <div className="dropper">
          <input
            className="image_upload"
            type="file"
            onChange={e => selectUploadImages(e.target.files)}
            multiple
            accept="image/*"
          />
          <span>Drag Files Here</span>
        </div>
        <div className="image_div">
          {images.map((image, index) => (
            <div key={index} style={{ display: "flex" }}>
              <img src={URL.createObjectURL(image)} alt="slide" />
              <Icon
                style={{ width: 40, color: "red", cursor: "pointer" }}
                name="remove"
                size={"big"}
                onClick={() => removeImage(index)}
              />
            </div>
          ))}
        </div>
        <Button loading={loading} color="green" onClick={() => upload()}>
          Upload
        </Button>
      </SlideShowMarkUp>
    </AdminMarkUp>
  );
};

export default connect(null, reduxFunctions)(AddSlideShowImages);
