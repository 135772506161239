import React from "react";

const Motto = () => {
  return (
    <div className="profile">
      <center>
        <h1>Motto</h1>
      </center>
      <p>
        "Be your own lamp,and help yourself" is the motto of Ananda Balika
        Vidyalaya . This was preached by lord buddha about three months before
        parinirvana
      </p>
      <p>අතත දීපා විහරථ</p>
      <p>
        "තමා තමාටම පහනක් වන්න." යන අරුත දෙන අතත දීපා විහරථ යන්න ආනන්ද බාලිකා
        විද්‍යාලයේ ආදර්ශ පාඨයයි. බුදුරජාණන් වහන්සේ පිරිනිවන් පෑමට තෙමසකට පෙර
        භික්ෂූන්ට අවවාද වශයෙන් ශ්‍රී මුඛයෙන් දේශනා කළ මහා පරිනිර්වාණ සූත්‍රයේ
        අඩංගු ගාථවෙන් උපුටා ගෙන ඇත. "අතත දීපා විහරථ අතත සරණා නඅඤඤ සරණා" මහණෙනි
        තමාට තමාම පහනක් කරගෙන වාසය කරන්න.තමාට තමාම පිහිටක් කරගන්න"
      </p>
    </div>
  );
};

export default Motto;
