import React from "react";
import { Image } from "semantic-ui-react";

const LeftImages = () => {
  return (
    <div style={{ textAlign: "center", margin: "0 auto" }}>
      <Image
        style={{ margin: "0 auto" }}
        src="./images/home_page/1.jpg"
        alt="history"
      />
      <br />

      <Image
        style={{ margin: "0 auto" }}
        src="./images/home_page/2.jpg"
        alt="history"
      />
      <br />
      <Image
        style={{ margin: "0 auto" }}
        src="./images/home_page/3.jpg"
        alt="history"
      />
    </div>
  );
};

export default LeftImages;
