import React from "react";
import GoogleMapReact from "google-map-react";
import { Icon } from "semantic-ui-react";

const Marker = () => <Icon name="marker" size="big" color="red" />;

const Location = () => {
  const center = {
    lat: 6.9268,
    lng: 79.8692
  };
  const zoom = 15;

  const apiKey = "AIzaSyBDTIkrq_EQJUC8o2tW1-ASi7LIN0nbaUA";

  return (
    <div
      style={{
        height: "60vh",
        width: "100%",
        margin: "auto",
        borderRadius: 20
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <Marker lat={6.926822} lng={79.869203} />
      </GoogleMapReact>
    </div>
  );
};

export default Location;
