import React from "react";
import AdminMarkUp from "./AdminMarkUp";

const Admin = () => {
  return (
    <AdminMarkUp>
      <h1>Admin</h1>
    </AdminMarkUp>
  );
};

export default Admin;
