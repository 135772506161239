import React, { Suspense, lazy } from "react";
import MarkUp from "../MarkUp";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { Grid } from "@material-ui/core";
import PrincipleMessage from "./PrincipleMessage/PrincipleMessage";
import News from "./News/News";
import Events from "./Events/Events";
import HomeFooter from "./HomeFooter/HomeFooter";

const SlideShow = lazy(() => import("./SlideShow/SlideSlow"));

const HomePage = () => {
  return (
    <>
      <MarkUp>
        <center>
          <div className="anim">
            <center>
              <h2>
                අ<span style={{ letterSpacing: -5 }}>තත</span> {"     "}දීපා
                විහරථ
              </h2>
            </center>
          </div>
        </center>

        <Suspense fallback={<LoadingComponent />}>
          <SlideShow />
        </Suspense>
        <div className="container">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4}>
              <PrincipleMessage />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Events />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <News />
            </Grid>
          </Grid>
        </div>
        <br />
        <HomeFooter />
      </MarkUp>
    </>
  );
};

export default HomePage;
