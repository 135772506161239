/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, Input, Button } from "semantic-ui-react";

const SingleStaff = ({ staff, userId, storeRef, index }) => {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(staff);

  // Edit Name
  const editData = async () => {
    try {
      await storeRef.doc(staff.id).update(data);
      alert("Edited");
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete Staff
  const deleteStaff = async () => {
    try {
      if (confirm("Are you Sure?")) {
        await storeRef.doc(staff.id).delete();
        alert("deleted");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div>
      <h4 style={{ color: "white" }}>
        {index + 1}). {data?.name}
      </h4>
      {userId && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Icon
            name="edit"
            size="large"
            onClick={() => setEdit(!edit)}
            style={{ cursor: "pointer" }}
          />
          <Icon
            name="trash"
            size="large"
            onClick={() => deleteStaff()}
            style={{ cursor: "pointer", color: "darkRed" }}
          />
        </div>
      )}
      {userId && edit && (
        <>
          <Input
            value={data?.name}
            name="edit"
            size="large"
            onChange={(e) => setData({ ...data, name: e.target.value })}
            style={{ cursor: "pointer", marginRight: 10 }}
          />

          <Button color="green" onClick={() => editData()}>
            Edit
          </Button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.firebase?.auth?.uid,
  };
};

export default connect(mapStateToProps)(SingleStaff);
