import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import { fireStore } from "../../../../config/firebase";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const principleMessageRef = fireStore().collection("principle_messages");

const PrincipleMessage = () => {
  const [principle, setPrinciple] = useState(null);
  const [loading, setLoading] = useState(true);

  const getPrincipleMessages = async () => {
    let gottedPrinciple = [];
    try {
      await (
        await principleMessageRef.orderBy("updatedAt", "desc").limit(1).get()
      ).forEach(item => {
        gottedPrinciple = [...gottedPrinciple, { ...item.data(), id: item.id }];
      });
      setPrinciple(gottedPrinciple[0]);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getPrincipleMessages();
  }, []);

  return (
    <div style={{ textAlign: "center" }} className="princple-msg">
      <center>
        <h1>A Message from Principal </h1>
      </center>
      <br />
      {loading && <LoadingComponent />}
      {principle && (
        <>
          <Image
            style={{ width: 250, margin: "0 auto" }}
            src={principle.image}
            alt="principle_image"
          />
          <p style={{ fontSize: 15 }}>{principle.message}</p>
        </>
      )}
    </div>
  );
};

export default PrincipleMessage;
