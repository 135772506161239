import React from "react";
import { Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

const Grade = ({ grade, link, image }) => {
  const history = useHistory();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={4}
      md={4}
      style={{ padding: 30, textAlign: "center", cursor: "pointer" }}
    >
      <div onClick={() => history.push(`/assignment/${grade}`)}>
        <Image src={image} style={{ borderRadius: 20 }} />
        <br />
        <p className="title">
          {grade === "Grade 12" ? "Advance Level" : grade}
        </p>
        <br />
      </div>
    </Grid>
  );
};

export default Grade;
