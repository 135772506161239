/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Select, Input, Button, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";

import AdminMarkUp from "../AdminMarkUp";
import { fireStore, storage } from "../../../../config/firebase";
import { useHistory } from "react-router-dom";

const subjectCollection = fireStore().collection("subjects");

const AddAssignments = ({ userId }) => {
  const history = useHistory();

  if (!userId) {
    history.push("/");
  }

  const grades = [
    { key: "Grade 06", text: "Grade 06", value: "Grade 06" },
    { key: "Grade 07", text: "Grade 07", value: "Grade 07" },
    { key: "Grade 08", text: "Grade 08", value: "Grade 08" },
    { key: "Grade 09", text: "Grade 09", value: "Grade 09" },
    { key: "Grade 10", text: "Grade 10", value: "Grade 10" },
    { key: "Grade 11", text: "Grade 11", value: "Grade 11" },
    { key: "Advance Level", text: "Advance Level", value: "Advance Level" }
  ];

  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [grade, setGrade] = useState("");
  const [assignment, setAssignment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [quizUrl, setQuizUrl] = useState("");

  const subjectOptions =
    selectedSubjects.length > 0 &&
    selectedSubjects[0]?.subjects?.map(subject => {
      return { key: subject, value: subject, text: subject };
    });

  useEffect(() => {
    updateSubjects();
  }, [grade]);

  const updateSubjects = () => {
    if (
      grade === "Grade 06" ||
      grade === "Grade 07" ||
      grade === "Grade 08" ||
      grade === "Grade 09"
    ) {
      setSelectedSubjects(
        Array.from(subjects.filter(subject => subject.id === "grade_6_9"))
      );
    } else if (grade === "Grade 10" || grade === "Grade 11") {
      setSelectedSubjects(
        Array.from(subjects.filter(subject => subject.id === "grade_10_11"))
      );
    } else if (grade === "Advance Level") {
      setSelectedSubjects(
        Array.from(subjects.filter(subject => subject.id === "grade_12_13"))
      );
    }
  };

  // Get Subjects
  const getSubjects = async () => {
    let allSubjects = [];
    try {
      await (await subjectCollection.get()).forEach(doc => {
        allSubjects = [...allSubjects, { ...doc.data(), id: doc.id }];
      });

      setSubjects(allSubjects);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getSubjects();
  }, []);

  // Upload Assignment
  const uploadAssignment = e => {
    e.preventDefault();
    setLoading(true);
    if (!grade || !subject || (!assignment && !quizUrl)) {
      alert("Fill Subject, Grade and Select file or Input QuizUrl");
      setLoading(false);
    } else {
      if (assignment) {
        uploadFireStorage();
      } else {
        uploadFireStore();
      }
    }
  };

  const uploadFireStorage = () => {
    storage()
      .ref("assignments")
      .child(`${grade}/${subject}/${assignment.name}`)
      .put(assignment)
      .on(
        "state_changed",
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          alert(error.message);
          setLoading(false);
        },
        async () => {
          // error
          await storage()
            .ref(`assignments/`)
            .child(`${grade}/${subject}/${assignment.name}`)
            .getDownloadURL()
            .then(async url => {
              uploadFireStore(url);
            })
            .catch(err => {
              alert(err.message);
              setLoading(false);
            });
        }
      );
  };

  const uploadFireStore = url => {
    const time = new Date().getTime();
    const date = new Date(time);

    fireStore()
      .collection(`assignments/${grade}/${subject}`)
      .add({
        assignmentUrl: url ? url : null,
        quiz: quizUrl ? quizUrl : null,
        specialNot: note ? note : null,
        createdBy: userId,
        createdAt: date.toString(),
        updatedAt: Date.now(),
        name: assignment ? assignment.name : null
      })
      .then(() => {
        alert("Assignment Uploaded");
        setLoading(false);
      })
      .catch(err => {
        alert(err.message);
        setLoading(false);
      });
  };

  return (
    <AdminMarkUp>
      <h1>Add Assignments</h1>
      <Form onSubmit={e => uploadAssignment(e)}>
        <Form.Field>
          <Select
            value={grade}
            onChange={(e, data) => {
              setGrade(data.value);
            }}
            placeholder="Select Grade"
            options={grades}
            multiple={false}
          />
        </Form.Field>
        <Form.Field>
          <Select
            value={subject}
            onChange={(e, data) => {
              setSubject(data.value);
            }}
            placeholder="Select Subject"
            options={subjectOptions}
            multiple={false}
          />
        </Form.Field>
        <Form.Field>
          <Input
            value={quizUrl}
            onChange={e => {
              setQuizUrl(e.target.value);
            }}
            placeholder="Quiz Link"
          />
        </Form.Field>
        <Form.Field>
          <TextArea
            value={note}
            onChange={e => {
              setNote(e.target.value);
            }}
            placeholder="Special Notes"
          />
        </Form.Field>

        <Form.Field>
          <Input
            type="file"
            onChange={e => setAssignment(e.target.files[0])}
            multiple="false"
          />
        </Form.Field>
        <Button type="submit" color="green" loading={loading}>
          Upload Assignment
        </Button>
      </Form>
    </AdminMarkUp>
  );
};

const mapStateToProps = state => {
  return {
    userId: state?.firebase?.auth?.uid
  };
};

export default connect(mapStateToProps)(AddAssignments);
