import React from "react";
import MarkUp from "../MarkUp";
import Location from "./Location/Location";
import { Grid } from "@material-ui/core";
import Info from "./Info/Info";

const ContactUs = () => {
  return (
    <MarkUp>
      <div className="container">
        <center>
          <h1 style={{ textAlign: "center", marginBottom:'2em' }} className='contactus'>Contact Us</h1>
        </center>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Info />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingTop:'2em'}}>
            <Location />
          </Grid>
        </Grid>
      </div>
    </MarkUp>
  );
};

export default ContactUs;
