/* eslint-disable array-callback-return */
import { ADD_SLIDE_SHOW_IMAGES, GET_SLIDE_SHOW_IMAGES } from "../../types";
import { storage, fireStore } from "../../../config/firebase";

const slideCollection = fireStore().collection("slide_images");

// Add Slide Show Images
export const addSlideShowImages = (images, setLoading) => async dispatch => {
  try {
    let imageUrls = [];
    await images.map(file => {
      storage()
        .ref(`slide_images/${file.name}`)
        .put(file)
        .on(
          "state_changed",
          snapshot => {
            // console.log(snapshot);
          },
          error => {
            alert(error.message);
          },
          async () => {
            // error
            await storage()
              .ref(`slide_images/`)
              .child(file.name)
              .getDownloadURL()
              .then(async url => {
                imageUrls = [...imageUrls, url];
                slideCollection
                  .add({
                    slideImage: url,
                    createdAt: Date.now(),
                    name: file.name
                  })
                  .then(() => {
                    if (images.length === imageUrls.length) {
                      dispatch({
                        type: ADD_SLIDE_SHOW_IMAGES,
                        payload: { images: imageUrls }
                      });
                      setLoading(false);
                    }
                  })
                  .catch(err => alert(err.message));
              })
              .catch(err => alert(err.message));
          }
        );
    });
  } catch (err) {
    setLoading(false);
    console.error(err.message);
  }
};

// Get Slide_Show Images
export const getSlideShowImages = () => async dispatch => {
  try {
    let images = [];

    (await slideCollection.orderBy("createdAt", "desc").limit(5).get()).forEach(
      doc => (images = [...images, doc.data()])
    );
    dispatch({
      type: GET_SLIDE_SHOW_IMAGES,
      payload: { images }
    });
  } catch (err) {
    console.error(err.message);
  }
};
